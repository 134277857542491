import React, { useEffect, useState, useRef } from "react";
import { Grid, GridListTile, FormControl, InputLabel, Select, Input, Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useStyles from "./styles";
import axios from 'axios';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { findInObjectData, getDataInList } from '../../helpers/storedDataHelper';
import { objectTableOptions, getMuiTheme } from './TableOptions';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Chip } from "../../components/Wrappers";
import checkedImage from '../../images/CheckMark.png';
import checkedImageYellow from '../../images/CheckMarkOrange.png';
import checkedImageBlack from '../../images/CheckMarkBlack.png';
import {
    Menu,
    MenuItem,
} from "@material-ui/core";

import {
    FileCopy,
    Edit,
    Delete,
    Description,
    MoreVert,
    MergeType,
    Speed,
    Menu as MenuIcon,
    MailOutline as MailIcon,
    NotificationsNone as NotificationsIcon,
    Person as AccountIcon,
    Search as SearchIcon,
    Send as SendIcon,
    ArrowBack as ArrowBackIcon,
    AccountCircle as ProfileIcon
} from '@material-ui/icons';

export default function ObjectTable() {
    const [showMenu, setShowMenu] = useState(false);
    const [canCreateObjects, setCanCreateObjects] = useState(false);
    const [canCreateTemplates, setCanCreateTemplates] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState();
    const [tab, setTab] = React.useState(0);
    const [objectData, setObjectData] = React.useState([]);
    const [customerFilter, setCustomerFilter] = useState();
    const [facilityFilter, setFacilityFilter] = useState();
    const [pressureFilters, setPressureFilters] = useState([]);
    const [liftFilters, setLiftFilters] = useState([]);
    const [pressureObjectColumns, setPressureObjectColumns] = useState([]);
    const [liftObjectColumns, setLiftObjectColumns] = useState([]);
    const [pressureObjects, setPressureObjects] = useState([]);
    const [liftObjects, setLiftObjects] = useState([]);
    const classes = useStyles();
    const pressureMuiTable = useRef(null);
    const liftMuiTable = useRef(null);
    const currentObjectColumnsVersion = "20240125";

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            currentUser = JSON.parse(currentUser);

            if (currentUser.userPermission) {
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "41") > -1) {
                    setCanCreateObjects(true);
                } else {
                    setCanCreateObjects(false);
                }
                if (currentUser.userPermission.findIndex(perm => perm.permissionID === "81") > -1) {
                    setCanCreateTemplates(true);
                } else {
                    setCanCreateTemplates(false);
                }
            }
        }

        const params = window.location.href.split('?')[1];

        let customerName;
        let facilityName;

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);

            customerName = urlParams.get('kundnamn');
            facilityName = urlParams.get('anläggningsNamn');
            const startingTab = urlParams.get('objektTyp');

            if (startingTab) {
                if (startingTab === '0') {
                    setTab(0);
                } else if (startingTab === '1') {
                    setTab(1);
                }
            }
        }

        let pressureObjCols = [
            {
                name: "",
                options: {
                    display: true,
                    filter: false,
                    viewColumns: false
                }
            },
            {
                name: "Kund",
                options: {
                    display: false,
                }
            },
            {
                name: "Anläggning",
                options: {
                    display: true,
                }
            },
            {
                name: "Avdelning",
                options: {
                    display: true,
                }
            },
            {
                name: "Plats",
                options: {
                    display: false
                }
            },
            {
                name: "Objektnr",
                options: {
                    display: true,
                    filter: false
                }
            },
            {
                name: "Objektnamn",
                options: {
                    display: false,
                    filter: false
                }
            },
            {
                name: "Objekttyp",
                options: {
                    display: true,
                }
            },
            {
                name: "Tillv.-nr",
                options: {
                    display: false,
                    filter: false
                }
            },
            {
                name: "Klass",
                options: {
                    display: true,
                    //customHeadRender: (columnMeta, handleToggleColumn) => { //Not working
                    //    return <th style={{ textAlign: "center" }}><span>Klass</span></th>
                    //},
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ paddingLeft: "20px" }}>{value}</div>
                    },
                }
            },
            {
                name: "Storlek",
                options: {
                    hint: "Volym (L)/ Volym (m3) / DN / Effekt(kW)",
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ textAlign: "center" }}>{value}</div>
                    },
                }
            },
            {
                name: "FLT intervall",
                options: {
                    display: false,
                    hint: "Intervall för Fortlöpande Tillsyn",
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        return <div style={{ textAlign: "center" }}>{findInObjectData('fltIntervals', value, 'listDisplayName')}</div>
                    }
                }
            },
            {
                name: "Nästa FLT",
                options: {
                    display: false,
                    hint: "Datum för nästa Fortlöpande Tillsyn",
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        switch (fltSplit[0]) {
                            case "-1":
                                return (<span style={{ textAlign: "center", whiteSpace: "nowrap" }}>Saknas</span>)
                            case "0":
                                return <img style={{ width: "32px" }} src={checkedImageYellow} />
                            case "1":
                                return <img style={{ width: "32px" }} src={checkedImage} />
                        }

                        let valueText;

                        if (fltSplit[0].length < 3) {
                            valueText = findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName');
                        } else {
                            valueText = fltSplit[0];
                        }

                        if (fltSplit[0] === "-1") {
                            return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        }

                        if (fltSplit.length === 2) {
                            if (fltSplit[1].length > 0) {
                                return (<Chip
                                    className={classes.badge}
                                    label={valueText}
                                    style={{ backgroundColor: fltSplit[1] }}
                                />)
                            }
                        }

                        return <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{valueText}</div>;
                    },
                }
            },
            {
                name: "Regnr. AKO",
                options: {
                    display: false,
                    hint: "ID-nr / Reg-nr för Ackrediterat Kontrollorgan",
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span style={{ whiteSpace: "nowrap" }}>{value}</span>
                    },
                }
            },
            {
                name: "Utförningsdatum DP",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        return cleanseDateStringFromT(value);

                        //let fltSplit = value.split(','); //Menu choice, Color

                        //if (fltSplit[0] === "-1") {
                        //    return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        //}

                        //if (!fltSplit[1]) {
                        //    return <span style={{ whiteSpace: "nowrap" }}>{fltSplit[0]}</span>;
                        //}

                        //if (fltSplit.length === 2 && fltSplit[1] !== "") {
                        //    if (fltSplit[0] === "-1") {
                        //        return (<Chip
                        //            className={classes.badge}
                        //            label={findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}
                        //            style={{ backgroundColor: fltSplit[1] }}
                        //        />)
                        //    } else {
                        //        return (<Chip
                        //            className={classes.badge}
                        //            label={fltSplit[0]}
                        //            style={{ backgroundColor: fltSplit[1] }}
                        //        />)
                        //    }
                        //}

                        //return findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName');
                    },
                }
            },
            {
                name: "DP intervall",
                options: {
                    hint: "Kontroll Driftprov",
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        if (fltSplit[0] === "-1") {
                            return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        }

                        if (fltSplit.length === 2 && fltSplit[1] !== "") {
                            return (<Chip
                                className={classes.badge}
                                label={findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}
                                style={{ backgroundColor: fltSplit[1] }}
                            />)
                        }

                        return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                    },
                }
            },
            {
                name: "DP FFM",
                options: {
                    hint: "Driftprov förfallomånad. Röd = Körförbud",
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        if (fltSplit[0] === "-1") {
                            return <div>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        }

                        if (!fltSplit[1]) {
                            return <span style={{ whiteSpace: "nowrap" }}>{fltSplit[0]}</span>;
                        }

                        if (fltSplit.length === 2 && fltSplit[1] !== "") {
                            if (fltSplit[0] === "-1") {
                                return (<Chip
                                    className={classes.badge}
                                    label={findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}
                                    style={{ backgroundColor: fltSplit[1] }}
                                />)
                            } else {
                                return (<Chip
                                    className={classes.badge}
                                    label={fltSplit[0]}
                                    style={{ backgroundColor: fltSplit[1] }}
                                />)
                            }
                        }

                        return findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName');
                    },
                }
            },
            {
                name: "Utförningsdatum IU",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        return cleanseDateStringFromT(value);

                        //let fltSplit = value.split(','); //Menu choice, Color

                        //if (fltSplit[0] === "-1") {
                        //    return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        //}

                        //if (!fltSplit[1]) {
                        //    return <span style={{ whiteSpace: "nowrap" }}>{fltSplit[0]}</span>;
                        //}

                        //if (fltSplit.length === 2 && fltSplit[1] !== "") {
                        //    if (fltSplit[0] === "-1") {
                        //        return (<Chip
                        //            className={classes.badge}
                        //            label={findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}
                        //            style={{ backgroundColor: fltSplit[1] }}
                        //        />)
                        //    } else {
                        //        return (<Chip
                        //            className={classes.badge}
                        //            label={fltSplit[0]}
                        //            style={{ backgroundColor: fltSplit[1] }}
                        //        />)
                        //    }
                        //}

                        //return findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName');
                    },
                }
            },
            {
                name: "IU intervall",
                options: {
                    hint: "Kontroll IU  (In och utvändigt)",
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        if (fltSplit[0] === "-1") {
                            return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        }

                        if (fltSplit.length === 2 && fltSplit[1] !== "") {
                            return (<Chip
                                className={classes.badge}
                                label={findInObjectData('iuInspections', parseInt(value), 'listDisplayName')}
                                style={{ backgroundColor: fltSplit[1] }}
                            />)
                        }

                        return <div style={{ textAlign: "center" }}>{findInObjectData('iuInspections', parseInt(value), 'listDisplayName')}</div>;
                    },
                }
            },
            {
                name: "IU FFM",
                options: {
                    hint: "In- och utvändigt, förfallomånad. Röd = Körförbud",
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        if (fltSplit[0] === "-1") {
                            return <div>{findInObjectData('iuInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        }

                        if (!fltSplit[1]) {
                            return <span style={{ whiteSpace: "nowrap" }}>{fltSplit[0]}</span>;
                        }

                        if (fltSplit.length === 2 && fltSplit[1] !== "") {
                            if (fltSplit[0] === "-1") {
                                return (<Chip
                                    className={classes.badge}
                                    label={findInObjectData('iuInspections', parseInt(fltSplit[0]), 'listDisplayName')}
                                    style={{ backgroundColor: fltSplit[1] }}
                                />)
                            } else {
                                return (<Chip
                                    className={classes.badge}
                                    label={fltSplit[0]}
                                    style={{ backgroundColor: fltSplit[1] }}
                                />)
                            }
                        }

                        return findInObjectData('iuInspections', parseInt(fltSplit[0]), 'listDisplayName');
                    },
                }
            },
            {
                name: "Sista dag ",
                options: {
                    hint: "Sista dagen objektet får användas utan ny kontroll",
                    display: true,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        let valueText;

                        if (fltSplit[0].length > 2) {
                            valueText = fltSplit[0];
                        } else {
                            valueText = findInObjectData('common', parseInt(value), 'listDisplayName')
                            return <span style={{ textAlign: "center", whiteSpace: "nowrap" }}>{valueText}</span>;
                        }

                        if (!fltSplit[1]) {
                            return <span style={{ textAlign: "center", whiteSpace: "nowrap" }}>{valueText}</span>;
                        } else {
                            return (<Chip
                                className={classes.badge}
                                label={valueText}
                                style={{ backgroundColor: fltSplit[1] }}
                            />)
                        }
                    },
                }
            },
            {
                name: "Utförare AKO",
                options: {
                    display: false,
                    hint: "Utförare, Ackrediterat Kontrollorgan",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return findInObjectData('executorAKO', value, 'listDisplayName')
                    }
                }
            },
            {
                name: "Riskb.",
                options: {
                    display: true,
                    filter: false,
                    hint: "Riskbedömning",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        switch (value) {
                            case "-1":
                                return (<span style={{ textAlign: "center", whiteSpace: "nowrap" }}>Saknas</span>)
                            case "0":
                                return <img style={{ width: "32px" }} src={checkedImageYellow} />
                            case "1":
                                return <img style={{ width: "32px" }} src={checkedImage} />
                            case "2":
                                return <img style={{ width: "32px" }} src={checkedImageBlack} />
                            default:
                                return;
                        }
                    },
                }
            },
            {
                name: "LLJ",
                options: {
                    display: true,
                    filter: false,
                    hint: "Om Livslängdsjournal behövs",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        /*
                            Riskbedömning och LLJ för objektlistan:
                            null = Syns inte
                            -1 = Saknas
                            0 = Nej, men finns
                            1 = Ja, finns och godkänd
                         */

                        switch (value) {
                            case "-1":
                                return (<span style={{ textAlign: "center", whiteSpace: "nowrap" }}>Saknas</span>)
                            case "0":
                                return <img style={{ width: "32px" }} src={checkedImageYellow} />
                            case "1":
                                return <img style={{ width: "32px" }} src={checkedImage} />
                            default:
                                return;
                        }
                    },
                }
            },
            {
                name: "LLJ Rev",
                options: {
                    display: true,
                    filter: false,
                    hint: "År för revidering av livslängdsjournal",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        if (fltSplit.length <= 1) {
                            return value;
                        }

                        if (!fltSplit[1]) {
                            return <span style={{ textAlign: "center", whiteSpace: "nowrap" }}>{value}</span>;
                        } else {
                            return (<Chip
                                className={classes.badge}
                                label={value}
                                style={{ backgroundColor: fltSplit[1] }}
                            />)
                        }

                        return
                    },
                }
            },
            {
                name: "Föreskrifter",
                options: {
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ textAlign: "center" }}>{value}</div>
                    }
                }
            },
            {
                name: "Tillverkare",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Tillv. år",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Pos.nr säkerhetsventil",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Avsäkringstryck säkerhetsventil",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Position kontroll-skylt",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Byggnad",
                options: {
                    display: false,
                    filter: false
                }
            },
            {
                name: "Rum",
                options: {
                    display: false,
                    filter: false
                }
            },
            {
                name: "Dörr",
                options: {
                    display: false,
                    filter: false
                }
            },
            {
                name: "Kommentar",
                options: {
                    display: false,
                    filter: false
                }
            },
            //{
            //    name: "Nästa kontroll",
            //    options: {
            //        display: false,
            //        hint: "Röd = körförbud",
            //        filter: false,
            //        customBodyRender: (value, tableMeta, updateValue) => {
            //            if (!value) {
            //                return;
            //            }

            //            let fltSplit = value.split(','); //Menu choice, Color

            //            if (!fltSplit[1]) {
            //                return fltSplit[0];
            //            }

            //            if (fltSplit.length === 2 && fltSplit[1] !== "") {
            //                if (fltSplit[0] === "-1") {
            //                    return (<Chip
            //                        className={classes.badge}
            //                        label={findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}
            //                        style={{ backgroundColor: fltSplit[1] }}
            //                    />)
            //                } else {
            //                    return (<Chip
            //                        className={classes.badge}
            //                        label={fltSplit[0]}
            //                        style={{ backgroundColor: fltSplit[1] }}
            //                    />)
            //                }
            //            }

            //            return findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName');
            //        },
            //    }
            //},
            {
                name: "Designtemperatur, lägsta",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Designtemperatur, högsta",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Designtryck, lägsta",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Designtryck, högsta",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Tillåtet övertryck",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Tillåtet undertryck",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Högst tillåten temperatur",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Lägst tillåten temperatur",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "Media",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Riskgrupp",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Ritningsnummer",
                options: {
                    filter: false,
                    display: false,
                }
            }
        ];

        saveColumn(pressureObjCols, 'defaultPressureCols');

        let liftObjCols = [
            {
                name: "",
                options: {
                    display: true,
                    filter: false,
                    viewColumns: false
                }
            },
            {
                name: "Kund",
                options: {
                    display: false,
                    filterList: customerFilter,
                }
            },
            {
                name: "Anläggning",
                options: {
                    display: true,
                    filterList: facilityFilter
                }
            },
            {
                name: "Avdelning",
                options: {
                    display: true,
                }
            },
            {
                name: "Plats",
                options: {
                    display: false
                }
            },
            {
                name: "Objektnr",
                options: {
                    display: true,
                    filter: false
                }
            },
            {
                name: "Objektnamn",
                options: {
                    display: false,
                    filter: false
                }
            },
            {
                name: "Objekttyp",
                options: {
                    display: true,
                }
            },
            {
                name: "Tillv.-nummer",
                options: {
                    display: true,
                    filter: false
                }
            },
            {
                name: "Lyftvikt",
                options: {
                    filter: false,
                    display: true,
                }
            },
            {
                name: "Längd",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Färg",
                options: {
                    filter: false,
                    display: true,
                }
            },
            {
                name: "Utrustningsgrupp",
                options: {
                    display: false,
                }
            },
            {
                name: "Senaste EGK",
                options: {
                    display: false,
                    filter: false,
                    hint: "Senaste Egenkontroll",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ textAlign: "center" }}>{value}</div>
                    },
                }
            },
            {
                name: "EGK intervall",
                options: {
                    display: false,
                    hint: "Egenkontroll, intervall",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        return <div style={{ textAlign: "center" }}>{findInObjectData('fltIntervalsLift', value, 'listDisplayName')}</div>
                    },
                }
            },
            {
                name: "Nästa EGK",
                options: {
                    display: true,
                    hint: "Datum för nästa Egenkontroll",
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color
                        let valueText;

                        if (fltSplit[0].length < 3) {
                            valueText = findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName');
                        } else {
                            valueText = fltSplit[0];
                        }

                        if (fltSplit[0] === "-1") {
                            return <div style={{ width: "100%", textAlign: "center" }}><div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div></div>;
                        }

                        if (fltSplit.length === 2) {
                            if (fltSplit[1].length > 0) {
                                return (
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <Chip
                                            className={classes.badge}
                                            label={valueText}
                                            style={{ backgroundColor: fltSplit[1] }}
                                        />
                                    </div>)
                            }
                        }

                        return <div style={{ textAlign: "center" }}>{valueText}</div>;
                    },
                }
            },
            {
                name: "Regnr. AKO",
                options: {
                    hint: "ID-nr / Reg-nr Ackrediterat Kontrollorgan",
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span style={{ whiteSpace: "nowrap" }}>{value}</span>
                    },
                }
            },
            {
                name: "Senaste besiktning utförd",
                options: {
                    display: false,
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value == null) {
                            return;
                        }

                        return <div style={{ textAlign: "center" }}>{value}</div>;
                    },
                }
            },
            {
                name: "Besiktning intervall", //Intervals' value arrives as numbers and not strings
                options: {
                    display: false,
                    hint: "Kontrollintervall kontrollorgan",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value == null) {
                            return;
                        }

                        return <div style={{ textAlign: "center" }}>{findInObjectData('liftInspections', value, 'listDisplayName')}</div>;
                    },
                }
            },
            {
                name: "Besiktning FFM",
                options: {
                    display: false,
                    filter: false,
                    hint: "Besiktning Förfallomånad. Röd = Körförbud",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        if (value === "-1" || value === "0") {
                            return <div style={{ textAlign: "center" }}>findInObjectData('common', parseInt(value), 'listDisplayName')</div>
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        if (!fltSplit[1]) {
                            return <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{fltSplit[0]}</div>
                        }

                        return (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Chip
                                    className={classes.badge}
                                    label={fltSplit[0]}
                                    style={{ backgroundColor: fltSplit[1] }}
                                />
                            </div>
                        )
                    },
                }
            },
            {
                name: "Sista dag",
                options: {
                    display: true,
                    filter: false,
                    hint: "Sista dagen objektet får användas utan ny kontroll",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (!value) {
                            return;
                        }

                        let fltSplit = value.split(','); //Menu choice, Color

                        let valueText;

                        if (fltSplit[0].length > 2) {
                            valueText = fltSplit[0];
                        } else {
                            valueText = findInObjectData('common', parseInt(value), 'listDisplayName')
                        }

                        if (fltSplit[0] === "-1") {
                            return <div style={{ textAlign: "center" }}>{findInObjectData('dpInspections', parseInt(fltSplit[0]), 'listDisplayName')}</div>;
                        }

                        if (!fltSplit[1]) {
                            return <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{valueText}</div>;
                        } else {
                            return (<Chip
                                className={classes.badge}
                                label={valueText}
                                style={{ backgroundColor: fltSplit[1] }}
                            />)
                        }
                    },
                }
            },
            {
                name: "Utförare AKO Besiktning",
                options: {
                    display: false,
                    hint: "Utförare, Ackrediterat Kontrollorgan",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return findInObjectData('executorAKO', value, 'listDisplayName')
                    }
                }
            },
            {
                name: "Kontrollföreskrift",
                options: {
                    display: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ textAlign: "center" }}>{value}</div>
                    }
                }
            },
            {
                name: "Tillverkare",
                options: {
                    display: false,
                }
            },
            {
                name: "Tillv. år",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Placering kontrollskylt",
                options: {
                    display: false,
                }
            },
            {
                name: "Kommentar",
                options: {
                    display: false,
                    filter: false
                }
            },
        ];

        saveColumn(liftObjCols, 'defaultLiftCols');

        let colVersion = localStorage.getItem('objectColumnsVersion');
        //See if current version of columns is the same
        if (colVersion && currentObjectColumnsVersion === JSON.parse(colVersion)) {
            //If yes, get what columns should be visible from localStorage
            let pressureObjectColumnSettings = JSON.parse(localStorage.getItem('pressureObjectColumns'));

            if (pressureObjectColumnSettings) {
                for (var i = 0; i < pressureObjCols.length; i++) {
                    pressureObjCols[i].name = pressureObjectColumnSettings[i].name;
                    pressureObjCols[i].options.display = pressureObjectColumnSettings[i].options.display;
                }
            }

            let liftObjectColumnSettings = JSON.parse(localStorage.getItem('liftObjectColumns'));

            if (liftObjectColumnSettings) {
                for (var i = 0; i < liftObjCols.length; i++) {
                    liftObjCols[i].name = liftObjectColumnSettings[i].name;
                    liftObjCols[i].options.display = liftObjectColumnSettings[i].options.display;
                }
            }

            if (customerName || facilityName) {
                let pressureFilterListItems = [];
                let liftFilterListItems = [];
                if (customerName) {
                    for (var i = 0; i < pressureObjectColumnSettings.length; i++) {
                        if (i === 1) {
                            pressureFilterListItems[i] = [customerName];
                            liftFilterListItems[i] = [customerName];
                        } else {
                            pressureFilterListItems[i] = [];
                            liftFilterListItems[i] = [];
                        }
                    }
                } else if (facilityName) {
                    for (var i = 0; i < liftObjectColumnSettings.length; i++) {
                        if (i === 2) {
                            pressureFilterListItems[i] = [facilityName];
                            liftFilterListItems[i] = [facilityName];
                        } else {
                            pressureFilterListItems[i] = [];
                            liftFilterListItems[i] = [];
                        }
                    }
                }

                setPressureFilters(pressureFilterListItems);
                setLiftFilters(liftFilterListItems);
            } else {
                const pressureFilterListItems = localStorage.getItem('pressureFilterList');
                const liftFilterListItems = localStorage.getItem('liftFilterList');
                if (pressureFilterListItems) {
                    const pressureFilterList = JSON.parse(pressureFilterListItems);
                    setPressureFilters(pressureFilterList);
                }

                if (liftFilterListItems) {
                    const liftFilterList = JSON.parse(liftFilterListItems);
                    setLiftFilters(liftFilterList);
                }
            }
        } else {
            //If no, get default columns and overwrite localStorage
            let newPressureObjCols = [];

            for (var i = 0; i < pressureObjCols.length; i++) {
                newPressureObjCols.push({
                    name: pressureObjCols[i].name,
                    options: {
                        display: pressureObjCols[i].options.display
                    }
                });
            }

            let newLiftObjCols = [];

            for (var i = 0; i < liftObjCols.length; i++) {
                newLiftObjCols.push({
                    name: liftObjCols[i].name,
                    options: {
                        display: liftObjCols[i].options.display
                    }
                });
            }

            localStorage.setItem("pressureObjectColumns", JSON.stringify(newPressureObjCols));
            localStorage.setItem("liftObjectColumns", JSON.stringify(newLiftObjCols));
            localStorage.setItem("objectColumnsVersion", JSON.stringify(currentObjectColumnsVersion));
        }

        setPressureObjectColumns(pressureObjCols);
        setLiftObjectColumns(liftObjCols);
    }, []);

    useEffect(() => {
        if (pressureFilters?.length > 0) {
            let pressureColChange = [...pressureObjectColumns];
            for (var i = 0; i < pressureColChange.length; i++) {
                pressureColChange[i].options.filterList = pressureFilters[i];
            }

            setPressureObjectColumns(pressureColChange);
            localStorage.setItem('pressureFilterList', JSON.stringify(pressureFilters));
        }
    }, [pressureFilters]);

    useEffect(() => {
        if (liftFilters?.length > 0) {
            let liftColChange = [...liftObjectColumns];
            for (var i = 0; i < liftColChange.length; i++) {
                liftColChange[i].options.filterList = liftFilters[i];
            }

            setLiftObjectColumns(liftColChange);
            localStorage.setItem('liftFilterList', JSON.stringify(liftFilters));
        }
    }, [liftFilters]);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    }

    function saveColumn(column, columnName) {
        let newColumn = [];

        for (var i = 0; i < column.length; i++) {
            newColumn.push({
                name: column[i].name,
                options: {
                    display: column[i].options.display
                }
            });
        }

        localStorage.setItem(columnName, JSON.stringify(newColumn));
    }

    function loadColumn(column, columnName) {
        let savedColumn = JSON.parse(localStorage.getItem(columnName));
        if (savedColumn) {
            for (var i = 0; i < savedColumn.length; i++) {
                column[i].name = savedColumn[i].name;
                column[i].options.display = savedColumn[i].options.display;
            }
        }

        return column;
    }

    function fltCell(cellValue, rowIndex, columnIndex) {
        return (
            <div style={{ backgroundColor: "red" }}>Test</div>
        )
    }

    function getColoredCell(cellValue, rowIndex, columnIndex) {
        if (!cellValue) {
            return null;
        }

        const cellArray = cellValue.split(',');

        switch (cellArray.length) {
            case 2:
                return {
                    style: {
                        backgroundColor: 'red'
                    }
                };
            default:
                return null;
        }
    }

    useEffect(() => {
        getObjectsFromAPI();
    }, []);

    function getObjectsFromAPI() {
        axios.get("/Objects/data").then(
            res => {
                if (res.status === 200) {
                    sessionStorage.setItem('dataStorage', JSON.stringify(res.data));
                    setObjectData(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        axios.get("/Objects/type/1").then(
            res => {
                if (res.status === 200) {
                    setPressureObjects(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        axios.get("/Objects/type/2").then(
            res => {
                if (res.status === 200) {
                    setLiftObjects(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });
    }

    function sendDuplicatedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekt duplicerat!",
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function getShortYearAndMonth(dateString) {
        let splitString = dateString.split('-');

        console.log(dateString);
        console.log(splitString[0].substring(1, 3) + "-" + splitString[1]);

        return splitString[0].substring(1, 3) + "-" + splitString[1];
    }

    function menuClick(objectId) {
        if (!objectId) {
            setShowMenu(false);
            return;
        }

        setMenuPlacement(objectId);
        setShowMenu(true);
    }

    function resetPressureCols() {
        let tempCol = [...pressureObjectColumns]
        let newPressureCols = loadColumn(tempCol, 'defaultPressureCols');
        localStorage.setItem('pressureObjectColumns', JSON.stringify(newPressureCols));
        setPressureObjectColumns(newPressureCols);
    }

    function resetLiftCols() {
        let tempCol = [...liftObjectColumns]
        let newLiftCols = loadColumn(tempCol, 'defaultLiftCols');
        localStorage.setItem('liftObjectColumns', JSON.stringify(newLiftCols));
        setLiftObjectColumns(newLiftCols);
    }

    function excelFunction() {
        let dateTime = new Date();
        let month = dateTime.getMonth().toString();
        let currentCustomerFilter, category;

        if (tab === 0) {
            currentCustomerFilter = pressureMuiTable.current.state.filterList[1];
            category = "tryck";
        } else {
            currentCustomerFilter = liftMuiTable.current.state.filterList[1];
            category = "lyft";
        }

        if (month.length === 1) {
            month = "0" + month;
        }

        if (currentCustomerFilter.length === 0) {
            axios({
                url: "/Objects/getallExcelFile?mainTypeID=" + (tab + 1),
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', category + "-" + dateTime.getFullYear().toString() + month + dateTime.getDate().toString() + ".xlsx");
                document.body.appendChild(link);
                link.click();
            });
        } else {
            axios({
                url: "/Objects/getallExcelFile?mainTypeID=" + (tab + 1) + "&CustomerName=" + currentCustomerFilter[0],
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', currentCustomerFilter[0] + "-" + category + "-" + dateTime.getFullYear().toString() + month + dateTime.getDate().toString() + ".xlsx");
                document.body.appendChild(link);
                link.click();
            });
        }

        //axios.get("/Objects/getallExcelFile?CustomerName=" + "	LKAB Kraft").then(
        //    res => {
        //        if (res.status === 200) {
        //            console.log(res);
        //        }
        //    }
        //).catch(err => {
        //    console.log('err');
        //    console.log(err);
        //})
    }

    function sendDeletedNotification() {
        const componentProps = {
            type: "feedback",
            message: "Objekt borttaget!",
            variant: "contained",
            color: "success",
        };

        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000,
        };

        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function GetBtns(objectId) {
        return (
            <div style={{ cursor: "pointer" }} onClick={() => menuClick(objectId)}>
                <MoreVert id={"menuBtn" + objectId} />
            </div>
        )

        //return (
        //    <div style={{ display: "flex", justifyContent: "space-between", width: "160px", float: "right" }}>
        //        <div style={{ cursor: "pointer" }} onClick={() => duplicateBtnClick(objectId)}>
        //            <FileCopy />
        //        </div>
        //        <div style={{ cursor: "pointer" }} onClick={() => editBtnClick(objectId)}>
        //            <Edit />
        //        </div>
        //        <div style={{ cursor: "pointer" }} onClick={() => delBtnClick(objectId)}>
        //            <Delete />
        //        </div>
        //    </div>
        //)
    }

    function renderSelect(column, index) {
        const { classes, filterData, filterList, options } = this.props;
        const textLabels = options.textLabels.filter;

        return (
            <GridListTile key={index} cols={1} classes={{ tile: classes.gridListTile }}>
                <FormControl key={index} fullWidth>
                    <InputLabel htmlFor={column.name}>{column.label}</InputLabel>
                    <Select
                        fullWidth
                        value={filterList[index].length ? filterList[index].toString() : textLabels.all}
                        name={column.name}
                        onChange={event => this.handleDropdownChange(event, index, column.name)}
                        input={<Input name={column.name} id={column.name} />}>
                        <MenuItem value={textLabels.all} key={0}>
                            {textLabels.all}
                        </MenuItem>
                        {filterData[index].map((filterValue, filterIndex) => (
                            <MenuItem value={filterValue} key={filterIndex + 1}>
                                {filterValue != null ? filterValue.toString() : ''}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridListTile>
        );
    }

    function GetDocumentBtn(objectId) {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", width: "30px", float: "left" }}>
                <div style={{ cursor: "pointer" }} onClick={() => documentBtnClick(objectId)}>
                    <Description />
                </div>
            </div>
        )
    }

    function duplicateBtnClick(objectId) {
        if (!canCreateObjects) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            axios.post("/objects/copy/" + objectId).then(
                res => {
                    if (res.status === 200) {
                        sendDuplicatedNotification();
                        axios.get("/Objects/type/1").then(
                            res => {
                                if (res.status === 200) {
                                    setPressureObjects(res.data);
                                }
                            }
                        ).catch(err => {
                            console.log('err');
                            console.log(err);
                        });

                        axios.get("/Objects/type/2").then(
                            res => {
                                if (res.status === 200) {
                                    setLiftObjects(res.data);
                                }
                            }
                        ).catch(err => {
                            console.log('err');
                            console.log(err);
                        });
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })
        }
    }

    function editBtnClick(e, objectId) {
        if (!canCreateObjects) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (e.button === 0 && !e.ctrlKey) {
                window.location.href = '/#/app/objekt/redigera?objektID=' + objectId;
            } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                window.open(
                    '/#/app/objekt/redigera?objektID=' + objectId,
                    '_blank'
                );
            }
        }
    }

    function onPressureColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...pressureObjectColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        let incPressureObjCols = JSON.parse(localStorage.getItem('pressureObjectColumns'));
        let tempColIndex = incPressureObjCols.findIndex(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = true;
            incPressureObjCols[tempColIndex].options.display = true;
        } else if (action === "remove") {
            changingColumn.options.display = false;
            incPressureObjCols[tempColIndex].options.display = false;
        }

        localStorage.setItem('pressureObjectColumns', JSON.stringify(incPressureObjCols));

        //let tempPressureCols = [];

        //pressureObjectColumns.map(obj => {
        //    tempPressureCols.push(
        //        {
        //            name: obj.name,
        //            options: {
        //                display: obj.options.display
        //            }
        //        }
        //    );
        //});
        //console.log(tempPressureCols);
    }

    function onLiftColumnViewChange(changedColumn, action) {
        let tempObjectColumns = [...liftObjectColumns];
        let changingColumn = tempObjectColumns.find(object => object.name === changedColumn);

        if (action === "add") {
            changingColumn.options.display = true;
        } else if (action === "remove") {
            changingColumn.options.display = false;
        }

        localStorage.setItem('liftObjectColumns', JSON.stringify(tempObjectColumns));
    }

    function delBtnClick(objectId) {
        if (!canCreateObjects) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            if (window.confirm("Är du säker på att du vill ta bort objektet?")) {
                axios.delete("/objects/" + objectId).then(
                    res => {
                        if (res.status === 200) {
                            if (tab === 0) {
                                setPressureObjects(pressureObjects.filter(object => object.id != objectId));
                            } else {
                                setLiftObjects(liftObjects.filter(object => object.id != objectId));
                            }

                            sendDeletedNotification();
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }
        }
    }

    function documentBtnClick(e, objectID) {
        if (e.button === 0 && !e.ctrlKey) {
            window.location.href = '/#/app/objekt/dokument?objektID=' + objectID;
        } else if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(
                '/#/app/objekt/dokument?objektID=' + objectID,
                '_blank'
            );
        }
    }

    function createFltBtnClick(e, objectId) {
        if (!canCreateTemplates) {
            sendNotification("Du har inte behörighet att göra detta", false);
        } else {
            const currentOject = pressureObjects.find(object => object.id === objectId);

            if (currentOject.assessmentDataDto.riskAssessment !== "1") {
                alert("Det behövs ett Riskbedömningsdokument för objektet innan du kan skapa ett dokument för Fortlöpande Tillsyn");
            }


            if (currentOject.assessmentDataDto.riskAssessment === "1") {
                //axios.get("/Templates/formTemplate/7/" + objectId).then(
                //    res => {
                //        if (res.status === 200) {
                //            let docName = currentOject.objectNr.length > 0 ? "Fortlöpande tillsyn (FLT)_" + currentOject.objectNr : "Fortlöpande tillsyn (FLT)";

                //            let formTemplateId = res.data[0].id;

                //            let templatePayload = {
                //                objectID: objectId,
                //                formTemplateID: formTemplateId,
                //                questionIDs: "",
                //                newFormGroupName: "",
                //                fltIsOkLift: false
                //            };

                //            axios.post('/Templates/CreateNewTemplate', templatePayload).then(res => {
                //                if (res.status === 200) {
                //                    window.location.href = '/#/app/mallar/mall?dokumentNamn=' + docName + '&objektID=' + objectId + "&mallID=" + res.data.id + "&dokumentTypID=7&skapa";
                //                }
                //                else {
                //                    alert("Misslyckades med att skapa ett nytt Fortlöpande tillsyns (FLT) dokument");
                //                }
                //            }).catch(err => {
                //                console.log('err');
                //                console.log(err);
                //            })
                //        }
                //        else {
                //            alert("Misslyckades med att skapa ett nytt Fortlöpande tillsyns (FLT) dokument");
                //        }
                //    }
                //).catch(err => {
                //    console.log('err');
                //    console.log(err);
                //});
                axios.get("/Templates/formTemplate/7/" + objectId).then(
                    res => {
                        if (res.status === 200) {
                            let formTemplateId = res.data[0].id;

                            let templatePayload = {
                                objectID: objectId,
                                formTemplateID: formTemplateId,
                                questionIDs: "",
                                newFormGroupName: "",
                                fltIsOkLift: false
                            };

                            return axios.post("/Templates/CreateNewTemplate", templatePayload);
                        }
                    }
                ).then(res => {
                    if (res.status === 200) {
                        let docName = currentOject.objectNr.length > 0 ? "Fortlöpande tillsyn (FLT)_" + currentOject.objectNr : "Fortlöpande tillsyn (FLT)";

                        window.location.href = "/#/app/mallar/mall?dokumentNamn=" + docName + "&objektID=" + objectId + "&mallID=" + res.data.id + "&dokumentTypID=7&skapa";
                    }
                }).catch(err => {
                    console.error(err);
                    alert(err.message);
                });
            }
        }
    }

    function cleanseIncomingDateString(dateString) {
        if (dateString) {
            let ds = dateString.substring(0, 7);

            return ds;
        }

        return "";
    }

    function cleanseDateStringFromT(dateString) {
        if (dateString) {
            return dateString.split('T')[0];
        } else {
            return dateString;
        }
    }

    function getRedChip(label) {
        return (<Chip
            className={classes.badge}
            label={label}
            style={{
                /*backgroundColor: "#E40613"*/
                backgroundColor: "#808080"
            }}
        />)
    }

    //const getMuiTheme = () => createMuiTheme({
    //    overrides: {
    //        MUIDataTableBodyCell: {
    //            stackedCommon: {
    //                '@media (max-width: 959.95px)': {
    //                    height: "auto"
    //                }
    //            }
    //        }
    //    }
    //})

    //function findInObjectData(dataObjectGroupName, searchValue, propertyToReturn) {
    //    if (typeof (dataObjectGroupName) === 'undefined' || typeof (searchValue) === 'undefined') {
    //        console.log(['findInObjectData undefined! dataObjectGroupName, searchvalue:', dataObjectGroupName, searchValue]);
    //        return null;
    //    }

    //    try {
    //        let objectFromData = data[dataObjectGroupName].find(obj => obj.value === searchValue);

    //        if (typeof (objectFromData) === 'undefined') {
    //            return null;
    //        }

    //        if (!propertyToReturn) {
    //            return objectFromData;
    //        }

    //        return objectFromData[propertyToReturn];
    //    } catch (e) {
    //        console.log(e);
    //        console.log(['findInObjectData error: dataObjectGroupName, searchvalue:', dataObjectGroupName, searchValue]);
    //    }

    //    return null;
    //    //objectData.classifications.find(obj => obj.value === object.controlClass).name
    //}

    function setRowPropsPressure(row, dataIndex) {
        if (pressureObjects && pressureObjects.length > 0) {
            if (pressureObjects[dataIndex].isInactive) {
                return {
                    "style": { color: "grey", backgroundColor: "darkGrey" }
                };
            }
        }

        return null;
    }

    function setRowPropsLift(row, dataIndex) {
        if (liftObjects && liftObjects.length > 0) {
            if (liftObjects[dataIndex].isInactive) {
                return {
                    "style": { color: "grey", backgroundColor: "darkGrey" }
                };
            }
        }

        return null;
    }

    function onPressureFilterChange(changedColumn, filterList, type) {
        setPressureFilters(filterList);
    }

    function onLiftFilterChange(changedColumn, filterList, type) {
        console.log(filterList);
        setLiftFilters(filterList);
    }

    function sendNotification(text, greenOverRed) {
        const componentProps = {
            type: "feedback",
            message: text,
            variant: "contained",
            color: greenOverRed ? "success" : "error"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item style={{ paddingBottom: 0 }} xs={12}>
                    <Paper sx={{ paddingBottom: 0 }}>
                        <Box display={'flex'} sx={{ paddingBottom: 0 }} justifyContent={'center'}>
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                value={tab}
                                onChange={handleChangeTab}
                            >
                                <Tab
                                    label={<div>{<Speed className={classes.tabIcon} />} Trycksatta anordningar</div>}
                                    classes={{ wrapper: classes.icon }}
                                />
                                <Tab
                                    label={<div>{<MergeType className={classes.tabIcon} />} Lyft</div>}
                                    classes={{ wrapper: classes.icon }}
                                />
                            </Tabs>
                        </Box>
                    </Paper>
                </Grid>

                {showMenu && <Menu
                    id="profile-menu"
                    open={true}
                    className={classes.headerMenu}
                    onClose={() => menuClick(null)}
                    classes={{ paper: classes.profileMenu }}
                    anchorEl={document.getElementById("menuBtn" + menuPlacement)}
                    disableAutoFocusItem
                >
                    <MenuItem
                        onMouseDown={e => documentBtnClick(e, menuPlacement)}
                    >
                        <Description className={classes.profileMenuIcon} /> Visa/Ladda upp dokument
                    </MenuItem>
                    {
                        tab === 0 &&
                        <MenuItem
                            onMouseDown={e => createFltBtnClick(e, menuPlacement)}
                        >
                                <Description className={classes.profileMenuIcon} /> <span style={!canCreateTemplates ? { textDecoration: "line-through" } : {}}>Skapa Fortlöpande tillsyn (FLT)</span>
                        </MenuItem>
                    }
                    <MenuItem
                        onMouseDown={e => editBtnClick(e, menuPlacement)}
                    >
                        <Edit className={classes.profileMenuIcon} /> <span style={!canCreateObjects ? { textDecoration: "line-through" } : {}}>Redigera</span>
                    </MenuItem>
                    <MenuItem
                        onClick={() => duplicateBtnClick(menuPlacement)}
                    >
                        <FileCopy className={classes.profileMenuIcon} /> <span style={!canCreateObjects ? { textDecoration: "line-through" } : {}}>Duplicera</span>
                    </MenuItem>

                    <MenuItem
                        onClick={() => delBtnClick(menuPlacement)}
                    >
                        <Delete className={classes.profileMenuIcon} /> <span style={!canCreateObjects ? { textDecoration: "line-through" } : {}}>Ta bort</span>
                    </MenuItem>
                </Menu>}

                <Grid item style={{ paddingTop: "6px" }} xs={12}>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        {tab === 0 && (pressureObjects && pressureObjects.length > 0) && (<><MUIDataTable
                            title="Förteckning trycksatta anordningar"
                            data={
                                pressureObjects.map(
                                    (object, index) => {
                                        return [
                                            GetBtns(object.id, index),
                                            object.customer?.name,
                                            object.facility?.name,
                                            object.section ? object.section.name : null,
                                            object.placement,
                                            object.objectNr,
                                            object.isInactive ? getRedChip(object.objectName) : object.objectName,
                                            object.type?.name,
                                            object.manufacturerNr,
                                            object.controlClass !== null ? findInObjectData('classifications', object.controlClass, 'listDisplayName') : null,
                                            object.volume,
                                            object.fltInterval ? findInObjectData('fltIntervals', object.fltInterval, 'listDisplayName') : null,
                                            object.assessmentDataDto?.dateOfNextFltString,
                                            object.regNr, //Regnr
                                            object.dateOfLastDP,
                                            object.assessmentDataDto?.dpIntervall,
                                            object.assessmentDataDto?.dpffm,
                                            object.dateOfLastUI,
                                            object.assessmentDataDto?.iuIntervall,
                                            object.assessmentDataDto?.iuffm,
                                            object.assessmentDataDto?.permitExpirationString,
                                            object.executorAKODP, //Utförare AKO
                                            object.assessmentDataDto?.riskAssessment,
                                            object.assessmentDataDto?.llj,
                                            object.assessmentDataDto?.dateOfNextJournal,
                                            object.controlRegulation ? findInObjectData('controlRegulations', object.controlRegulation, 'listDisplayName') : null,
                                            object.manufacturer,
                                            object.manufacturerYear,
                                            object.safetyVentPosition,
                                            object.safetyVentPressure, //Avsäkringstryck säkerhetsventil
                                            object.controlSignPosition,
                                            object.building,
                                            object.room,
                                            object.door,
                                            object.comment,
                                            object.designTemperatureLowest,
                                            object.designTemperatureHighest,
                                            object.designPressureUnder,
                                            object.designPressureOver,
                                            object.allowedPressureOver,
                                            object.allowedPressureUnder,
                                            object.allowedTemperatureHighest,
                                            object.allowedTemperatureLowest,
                                            object.media,
                                            findInObjectData('dangerGroups', object.dangerGroup, 'listDisplayName'),
                                            object.blueprintNr,
                                        ]
                                    }
                                )
                            }
                            columns={pressureObjectColumns}
                            options={objectTableOptions(onPressureColumnViewChange, onPressureFilterChange, resetPressureCols, setRowPropsPressure, excelFunction, canCreateObjects)}
                            key={pressureObjects}
                            ref={pressureMuiTable}
                        /></>)}
                        {tab === 1 && (liftObjects && liftObjects.length > 0) && (<><MUIDataTable
                            title="Förteckning lyft"
                            data={
                                liftObjects.map(
                                    (object, index) => {
                                        return [
                                            GetBtns(object.id, index),
                                            object.customer.name,
                                            object.facility.name,
                                            object.section ? object.section.name : null,
                                            object.placement,
                                            object.objectNr,
                                            object.objectName,
                                            object.type.name,
                                            object.manufacturerNr,
                                            object.liftweight,
                                            object.length,
                                            object.color,
                                            object.assetGroup,
                                            cleanseDateStringFromT(object.dateOfLastFlt),
                                            object.fltInterval,
                                            object.assessmentDataDto.dateOfNextFltString,
                                            object.regNr,
                                            cleanseDateStringFromT(object.previousDateOfLiftInspection),
                                            object.liftInspectionInterval,
                                            object.assessmentDataDto.nextLiftInspectionString,
                                            object.assessmentDataDto.permitExpirationString,
                                            object.executorAKOLiftInspection, //Utförare AKO Besiktning
                                            object.controlRegulation ? findInObjectData('controlRegulationsLift', object.controlRegulation, 'listDisplayName') : null,
                                            object.manufacturer,
                                            object.manufacturerYear,
                                            object.controlSignPosition,
                                            object.comment
                                        ]
                                    }
                                )
                            }
                            columns={liftObjectColumns}
                            options={objectTableOptions(onLiftColumnViewChange, onLiftFilterChange, resetLiftCols, setRowPropsLift, excelFunction, canCreateObjects)}
                            key={liftObjects}
                            ref={liftMuiTable}
                        /></>)}
                    </MuiThemeProvider>
                </Grid>
            </Grid>
        </>
    );
}
