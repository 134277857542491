import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { modalTableOptions, getMuiTheme } from '../../pages/tables/TableOptions';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Notification from "../../components/Notification/Notification";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "./styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import { findInObjectData, cleanseDateStringFromT, getProperCurrentDate, getProperCurrentDateAddMonths } from '../../helpers/storedDataHelper';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import riskMatrixImage from './../../images/riskmatris.png';
import { Checkbox } from "@material-ui/core";
import { withStyles } from '@material-ui/styles'

import {
    ExpandMore as ExpandMoreIcon,
    Image,
    GridOn as RiskMatrix
} from "@material-ui/icons";

import { Button, Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
import { FormControlLabel, Radio, RadioGroup, CircularProgress } from "@material-ui/core";
import miniLogo from "../../images/CheckABminiopaque.png";

const TemplateItem = () => {
    const history = useHistory();
    const [shouldConfirmLeave, setShouldConfirmLeave] = React.useState(true);

    const [template, setTemplate] = useState();
    const [mainImage, setMainImage] = useState("");
    const [object, setObject] = useState(null);
    const fileInput = React.useRef(null);
    const [riskMatrixModalOpen, setRiskMatrixModalOpen] = React.useState(false);
    const [participantsModalOpen, setParticipantsModalOpen] = React.useState(false);
    const [basisModalOpen, setBasisModalOpen] = React.useState(false);
    const [imageModalOpen, setImageModalOpen] = React.useState(false);
    const [modalEnlargedImage, setModalEnlargedImage] = React.useState("");
    const [currentlySelectedQuestionId, setCurrentlySelectedQuestionId] = React.useState(-1);
    const [documentTypeId, setDocumentTypeId] = React.useState(-1);
    const [objectData, setObjectData] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [fltInterval, setFltInterval] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [sigImgAddress, setSigImgAddress] = React.useState("");
    const [journalIsNeeded, setJournalIsNeeded] = React.useState(0);
    const [allowProceed, setAllowProceed] = React.useState(false);
    const [questionsAnswered, setQuestionsAnswered] = React.useState(false);
    const [formCreatingNotUpdating, setFormCreatingNotUpdating] = React.useState(false);
    const [formId, setFormId] = React.useState("");
    const [approverChosen, setApproverChosen] = React.useState(false);
    const [nextFLTDate, setNextFLTDate] = React.useState(getProperCurrentDate());
    const [unauthorized, setUnauthorized] = React.useState(false);
    const [docObj, setDocObj] = React.useState({});
    const [expanded, setExpanded] = React.useState([]);
    const [permaSavedInfo, setPermaSavedInfo] = React.useState();
    const [showEmailNecessaryText, setShowEmailNecessaryText] = React.useState(false);
    const [isArchive, setIsArchive] = React.useState(false);
    const muiTable = useRef(null);
    const currentDate = new Date();

    const GreenCheckbox = withStyles({
        root: {
            color: "#17a506",
            paddingTop: "2px",
            '&$checked': {
                color: "#17a506",
                paddingTop: "2px",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [tempParticipants, setTempParticipants] = React.useState([
        {
            name: "",
            title: "",
            email: "",
            userID: ""
        },
        {
            name: "",
            title: "",
            email: "",
            userID: ""
        },
        {
            name: "",
            title: "",
            email: "",
            userID: ""
        },
        {
            name: "",
            title: "",
            email: "",
            userID: ""
        }
    ]);

    useEffect(() => {
        if (template) {
            let expansionList = [];
            let expansionGroup;
            let currentQuestion = null;

            for (var i = 0; i < template.groups.length; i++) {
                expansionGroup = [];
                for (var j = 0; j < template.groups[i].fields.length; j++) {
                    currentQuestion = submission.results[getTotalFieldIndexWithHeaders(i, j)];
                    if (formCreatingNotUpdating) {
                        expansionGroup.push(true);
                    } else {
                        if (currentQuestion.accidentChance > 0 || currentQuestion.comment.length > 0 || currentQuestion.images.length > 0) {
                            expansionGroup.push(true);
                        } else {
                            expansionGroup.push(false);
                        }
                    }

                    //if (submission.statusID === 2 || submission.statusID === 3) {
                    //    expansionGroup.push(true);
                    //} else {
                    //    expansionGroup.push(false);
                    //}
                }

                expansionList.push(expansionGroup);
            }

            setExpanded(expansionList);
        }
    }, [template]);

    useEffect(() => {
        //console.log("approverChosen, questionsAnswered");
        //console.log([approverChosen, questionsAnswered]);
        if (approverChosen && questionsAnswered) {
            setAllowProceed(true);
            //console.log("setAllowProceed true");
        } else {
            setAllowProceed(false);
            //console.log("setAllowProceed false");
        }
    }, [approverChosen, questionsAnswered]);

    const [tempBasisList, setTempBasisList] = useState([]);

    const [riskLevel, setRiskLevel] = useState({
        accidentChance: 1,
        accidentConsequence: 1
    });

    const participantsModalOpenNow = () => {
        let newTempParticipants = [];

        //console.log("submission.participants");
        //console.log(submission.participants);

        for (var i = 0; i < 4; i++) {
            if (i < submission.participants.length) {
                newTempParticipants.push(submission.participants[i]);
            } else {
                newTempParticipants.push({
                    name: "",
                    title: "",
                    email: "",
                    userID: ""
                });
            }
        }

        setTempParticipants(newTempParticipants);

        setParticipantsModalOpen(true);
    };

    const participantsModalCloseNow = (save) => {
        if (save) {
            let submissionChange = { ...submission };
            submissionChange.participants = [];

            for (var i = 0; i < tempParticipants.length; i++) {
                if (tempParticipants[i].name.length > 0 || tempParticipants[i].title.length > 0 || tempParticipants[i].email.length > 0 || tempParticipants[i].userID.length > 0) {
                    if (tempParticipants[i].email.length === 0) {
                        setShowEmailNecessaryText(true);

                        return;
                    } else {
                        submissionChange.participants.push(tempParticipants[i]);
                    }
                }
            }

            setSubmission(submissionChange);
        }

        setParticipantsModalOpen(false);
        setShowEmailNecessaryText(false);
    };

    const basisModalOpenNow = () => {
        setBasisModalOpen(true);
    };

    const basisModalCloseNow = (save) => {
        if (save) {
            const newDocs = object.documents.filter(currentDocument => docObj.id !== currentDocument.id);
            const data = muiTable.current.state.selectedRows.data;
            let tempBasisChange = [];

            for (var i = 0; i < object.documents.length; i++) {
                tempBasisChange.push(null);
            }

            for (var i = 0; i < data.length; i++) {
                tempBasisChange[data[i].dataIndex] = {
                    document: newDocs[data[i].index],
                    description: "",
                    documentName: newDocs[data[i].index].documentName,
                    documentTypeName: newDocs[data[i].index].type.name,
                    executiveDate: newDocs[data[i].index].executiveDate
                };
            }

            setTempBasisList(tempBasisChange);
        }

        setBasisModalOpen(false);
    };

    const imageModalOpenNow = (image) => {
        setModalEnlargedImage(image.filePath);
        setImageModalOpen(true);
    };

    const imageModalCloseNow = () => {
        setImageModalOpen(false);
    };

    const riskMatrixModalOpenNow = (questionId) => {
        setCurrentlySelectedQuestionId(questionId);
        let question = submission.results.find(question => question.questionID === questionId);

        if (question.accidentChance > 0 || question.accidentConsequence > 0) {
            setRiskLevel({ accidentChance: question.accidentChance, accidentConsequence: question.accidentConsequence });
        } else {
            setRiskLevel({ accidentChance: 1, accidentConsequence: 1 });
        }

        setRiskMatrixModalOpen(true);
    };

    const riskMatrixModalCloseNow = (save) => {
        if (save) {
            let submissionChange = { ...submission };
            let question = submissionChange.results.find(question => question.questionID === currentlySelectedQuestionId);

            question.accidentChance = riskLevel.accidentChance;
            question.accidentConsequence = riskLevel.accidentConsequence;

            setSubmission(submissionChange);
        }

        setRiskMatrixModalOpen(false);
    };

    const handleFile = async (event, questionID) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('File', file);
        formData.append('ObjectID', submission.objectID);

        axios.post('/Forms/imageUpload', formData).then(
            res => {
                if (res.status === 201) {
                    let submissionChange = { ...submission };
                    let resultIndex = submissionChange.results.findIndex(result => result.questionID === questionID);
                    submissionChange.results[resultIndex].images.push(res.data);
                    setSubmission(submissionChange);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        });

        return;
    }

    const [submission, setSubmission] = useState(
        {
            objectID: -1,
            name: "",
            executiveDate: "",
            documentID: "",
            statusID: 1,
            templateID: "",
            approver: "0",
            results: [],
            participants: [],
            basis: []
        });

    /*
     * results object:
     {
        questionID: 0,
        answerID: 0, //For alternative questions
        textAnswer: "", //For text questions
        accidentChance: 0,
        accidentConsequence: 0,
        comment: "",
        images: [
        "string"
        ]
     }
     * basis object, in array:
    {
        documentID: 0,
        description: ""
    }

    * participants object, in array:
    {
        name: "",
        title: "",
        email: "",
        userID: ""
    }
    */

    useEffect(() => {
        axios.get("/Objects/data").then(
            res => {
                if (res.status === 200) {
                    setObjectData(res.data);
                }
            }
        ).catch(err => {
            console.log('err');
            console.log(err);
        })

        const params = window.location.href.split('?')[1];

        if (params && params.length > 0) {
            const urlParams = new URLSearchParams(params);
            const objIdString = urlParams.get('objektID');
            const documentTypeId = urlParams.get('dokumentTypID');
            const docName = urlParams.get('dokumentNamn');
            const copyId = urlParams.get('kopiaID');
            const creatingNotEditing = urlParams.get('skapa');
            const archiveParam = urlParams.get('arkiv');

            if (archiveParam !== null) {
                setIsArchive(true);
            } else {
                setIsArchive(false);
            }

            if (creatingNotEditing !== null) {
                setFormCreatingNotUpdating(true);
            } else {
                setFormCreatingNotUpdating(false);
            }

            let tempFormId = urlParams.get('formID');
            let tempTemplate;
            let templateIdString = urlParams.get('mallID');
            let editable = false;
            let submissionChange = { ...submission };
            let newUsers = null;

            if (tempFormId) {
                setFormId(tempFormId);
            }

            if (copyId) {
                tempFormId = copyId;
            }

            if (documentTypeId) {
                setDocumentTypeId(parseInt(documentTypeId));
            }

            axios.get("/users/getallusersForms?includeCustomerName=true").then(
                res => {
                    if (res.status === 200) {
                        setUsers(res.data);
                        newUsers = res.data;
                    }
                }
            ).catch(err => {
                console.log('err');
                console.log(err);
            })

            if (objIdString) {
                submissionChange.objectID = parseInt(objIdString);

                axios.get("/objects/" + objIdString).then(
                    res => {
                        if (res.status === 200) {
                            setObject(res.data);
                            let tempBasisChange = [];

                            if (res.data.documents) {
                                for (var i = 0; i < res.data.documents.length; i++) {
                                    tempBasisChange.push(null);
                                }
                            }

                            if (res.data.images.length > 0) {
                                setMainImage("/downloadImage/" + res.data.images[0].id);
                            }

                            if (tempFormId) { //From already existing form
                                axios.get("/Forms/resultsWithMinimalJoins/" + tempFormId + "/").then(
                                    res2 => {
                                        if (res2.status === 200) {
                                            tempTemplate = res2.data.template;
                                            templateIdString = res2.data.template.id;
                                            submissionChange.templateID = res2.data.template.id;
                                            setDocumentTypeId(res2.data.template.documentType.id);
                                            setDocObj(res2.data.documents[0]);

                                            if (creatingNotEditing !== null) { //Set status to 1 if it has just been created, ie it's a copy from an existing form
                                                submissionChange.statusID = 1;
                                            } else {
                                                submissionChange.statusID = res2.data.statusID;
                                            }

                                            if (res2.data.documents[0].type.id === 8) { //Get these from the documents if the doc being created is a risk assessment
                                                if (res2.data.documents[0].fltInterval > -1) {
                                                    setFltInterval(res2.data.documents[0].fltInterval);
                                                }

                                                if (res2.data.documents[0].journalIsNeeded > -1) {
                                                    setJournalIsNeeded(res2.data.documents[0].journalIsNeeded);
                                                }
                                            } else if (res2.data.documents[0].type.id === 7) { //Get when FLT
                                                if (res2.data.statusID < 3) {
                                                    setNextFLTDate(getProperCurrentDateAddMonths(res.data.fltInterval, true));
                                                } else {
                                                    setNextFLTDate(res.data.dateOfNextFlt);
                                                }
                                            }

                                            if (res2.data.approver === null) {
                                                submissionChange.approver = "0";
                                            } else {
                                                submissionChange.approver = res2.data.approver;
                                                setApproverChosen(true);

                                                if (res2.data.approverUser) {
                                                    setSigImgAddress(res2.data.approverUser.signatureImage);
                                                }
                                            }

                                            let tempParticipantsChange = [...tempParticipants];

                                            if (newUsers) {
                                                let tempUser = null;

                                                for (var i = 0; i < res2.data.participants.length; i++) {
                                                    tempUser = newUsers.find(userTemp => userTemp.userName === res2.data.participants[i].email);

                                                    if (tempUser) {
                                                        tempParticipantsChange[i] = {
                                                            name: res2.data.participants[i].name,
                                                            title: res2.data.participants[i].title,
                                                            email: res2.data.participants[i].email,
                                                            userID: tempUser.id
                                                        };
                                                    }
                                                }

                                                setTempParticipants(tempParticipantsChange);
                                            }

                                            //console.log("setTemp");
                                            //console.log(tempParticipantsChange);

                                            //axios.get("/Templates/" + res.data.templateID).then( 
                                            //    res2 => {
                                            //        if (res2.status === 200) {
                                            //            setDocumentTypeId(res2.data.documentType.id);
                                            //        }
                                            //    }
                                            //).catch(err => {
                                            //    console.log('err');
                                            //    console.log(err);
                                            ////});

                                            //if (res.data.approver) {
                                            //    submissionChange.approver = res.data.approver;

                                            //    if (res.data.statusID === 3) {
                                            //        axios.get("/Users/" + res.data.approver).then(
                                            //            res2 => {
                                            //                if (res2.status === 200) {
                                            //                    console.log("res2.data.signatureImage");
                                            //                    console.log(res2.data.signatureImage);
                                            //                    setSigImgAddress(res2.data.signatureImage);
                                            //                }
                                            //            }
                                            //        ).catch(err => {
                                            //            console.log('err');
                                            //            console.log(err);
                                            //        });
                                            //    }
                                            //} else {
                                            //    submissionChange.approver = "0";
                                            //}

                                            if (res2.data.statusID < 3) {
                                                editable = true;
                                            }

                                            //console.log("res2.data");
                                            //console.log(res2.data);

                                            submissionChange.participants = res2.data.participants;
                                            submissionChange.basis = res2.data.bases;

                                            for (var i = 0; i < res2.data.bases.length; i++) {
                                                tempBasisChange[i] = res2.data.bases[i];
                                            }

                                            setTempBasisList(tempBasisChange);

                                            if (res2.data.results.length === 0) {
                                                for (var i = 0; i < tempTemplate.groups.length; i++) {
                                                    for (var j = 0; j < tempTemplate.groups[i].fields.length; j++) {
                                                        submissionChange.results.push(
                                                            {
                                                                questionID: tempTemplate.groups[i].fields[j].question.id,
                                                                answerID: null,
                                                                textAnswer: null,
                                                                addAsMeasure: false,
                                                                accidentChance: 0,
                                                                accidentConsequence: 0,
                                                                comment: "",
                                                                images: [],
                                                                question: null
                                                            }
                                                        );
                                                    }
                                                }

                                            } else {
                                                for (var i = 0; i < res2.data.results.length; i++) {
                                                    submissionChange.results.push(
                                                        {
                                                            questionID: res2.data.results[i].question.id,
                                                            answerID: res2.data.results[i].answer ? res2.data.results[i].answer.id : null,
                                                            addAsMeasure: res2.data.results[i].addAsMeasure ? res2.data.results[i].addAsMeasure : false,
                                                            textAnswer: res2.data.results[i].textAnswer ? res2.data.results[i].textAnswer : null,
                                                            accidentChance: res2.data.results[i].accidentChance,
                                                            accidentConsequence: res2.data.results[i].accidentConsequence,
                                                            comment: res2.data.results[i].comment,
                                                            images: res2.data.results[i].images,
                                                            hideComment: res2.data.results[i].question.hideComment
                                                        }
                                                    );
                                                }

                                                let unansweredQuestionFound = false;
                                                for (var i = 0; i < submissionChange.results.length; i++) { //Needs to be here or React will not detect the change in time
                                                    //console.log(submissionChange.results[i]);
                                                    if (submissionChange.results[i].answerID != null) {
                                                        //console.log("choiceAnswer");
                                                        if (submissionChange.results[i].answerID === 0) {
                                                            unansweredQuestionFound = true;
                                                            console.log(i + " failed");
                                                            break;
                                                        }
                                                    } else if (submissionChange.results[i].textAnswer != null) {
                                                        //console.log("textAnswer");
                                                        if (submissionChange.results[i].textAnswer.length === 0) {
                                                            unansweredQuestionFound = true;
                                                            //console.log(i + " failed");
                                                            break;
                                                        }
                                                    } else {
                                                        let sortedIndices = getIndexFromTotal(tempTemplate, i);

                                                        if (tempTemplate.groups[sortedIndices[0]].fields[sortedIndices[1]].question.questionType !== "header") {
                                                            unansweredQuestionFound = true;
                                                            //console.log("Could not detect question type in " + i);
                                                            break;
                                                        }
                                                    }
                                                }

                                                setQuestionsAnswered(!unansweredQuestionFound);
                                                //console.log(!unansweredQuestionFound);
                                            }

                                            if (res2.data.statusID === 3) {
                                                axios.get("/Forms/formData/" + tempFormId).then(
                                                    formDataResult => {
                                                        if (res.status === 200) {
                                                            setPermaSavedInfo(formDataResult.data);
                                                            setSigImgAddress(formDataResult.data.approverSignatureImage);
                                                            if (res.data.images.length === 0) {
                                                                setMainImage(formDataResult.data.overviewImage);
                                                            }
                                                        }
                                                    }
                                                ).catch(err => {
                                                    console.log('err');
                                                    console.log(err);
                                                })
                                            }

                                            setTemplate(tempTemplate);
                                        } else if (res2.status === 401) {
                                            setUnauthorized(true);
                                        }
                                    }
                                ).catch(err => {
                                    console.log('err');
                                    console.log(err);
                                })
                            } else { //Making a new form from a template
                                if (res.data.fltInterval > -1) {
                                    setFltInterval(res.data.fltInterval);
                                }

                                if (res.data.journalIsNeeded > -1) {
                                    setJournalIsNeeded(res.data.journalIsNeeded);
                                }

                                if (res.data.fltInterval > -1) {
                                    setNextFLTDate(getProperCurrentDateAddMonths(res.data.fltInterval, true));
                                }

                                setTempBasisList(tempBasisChange);
                                if (templateIdString) {
                                    axios.get("/Templates/" + templateIdString).then(
                                        res => {
                                            if (res.status === 200) {
                                                tempTemplate = res.data;

                                                editable = true;

                                                for (var i = 0; i < tempTemplate.groups.length; i++) {
                                                    for (var j = 0; j < tempTemplate.groups[i].fields.length; j++) {
                                                        submissionChange.results.push(
                                                            {
                                                                questionID: tempTemplate.groups[i].fields[j].question.id,
                                                                answerID: null,
                                                                textAnswer: null,
                                                                accidentChance: 0,
                                                                accidentConsequence: 0,
                                                                comment: "",
                                                                addAsMeasure: false,
                                                                images: []
                                                            }
                                                        );
                                                    }
                                                }

                                                if (tempTemplate.documentType.id === 18 || tempTemplate.documentType.id === 19 || tempTemplate.documentType.id === 7) { //Get when checklist
                                                    let currentUser = localStorage.getItem('currentUser');

                                                    if (currentUser) { //The user is always the approver in checklists
                                                        currentUser = JSON.parse(currentUser);
                                                        submissionChange.approver = currentUser.id;
                                                        setApproverChosen(true);
                                                    }
                                                }
                                                
                                                setTemplate(tempTemplate);
                                            }
                                        }
                                    ).catch(err => {
                                        console.log('err');
                                        console.log(err);
                                    });
                                }
                            }
                        }
                    }
                ).catch(err => {
                    console.log('err');
                    console.log(err);
                })
            }

            submissionChange.templateID = templateIdString;

            if (docName) {
                submissionChange.name = docName;
            }

            if (editable) {
                if (!submissionChange.statusID) {
                    submissionChange.statusID = 1; //1 = Not yet approved
                }
            }

            setSubmission(submissionChange);
        }
    }, []);

    const classes = useStyles();

    const handleNext = (newStatus) => {
        setShouldConfirmLeave(false);
        setIsLoading(true);
        let readySubmission = { ...submission };
        let newBasis = [];

        //Make changes needed before submission
        //for (var i = 0; i < tempBasisList.length; i++) {
        //    if (tempBasisList[i] != null) {
        //        newBasis.push({
        //            documentID: tempBasisList[i].document.id,
        //            description: ""
        //        });
        //    }
        //}

        //Make changes needed before submission
        for (var i = 0; i < tempBasisList.length; i++) {
            if (tempBasisList[i] != null) {
                newBasis.push(tempBasisList[i]);
            }
        }

        for (var i = 0; i < readySubmission.participants.length; i++) {
            if (readySubmission.participants[i].name) {
                readySubmission.participants[i].name = readySubmission.participants[i].name.split('(')[0];
            }
        }

        readySubmission.basis = newBasis;

        if (!formCreatingNotUpdating) {
            readySubmission.documentID = docObj.id;
        }

        switch (template.documentType.id) {
            case 8: //Riskbedömning
                readySubmission.documentRiskAssessment = {
                    fltInterval: fltInterval,
                    journalIsNeeded: journalIsNeeded
                };

                break;
            case 7: //FLT
                readySubmission.documentFLTDto = {
                    dateOfNextFlt: nextFLTDate
                };

                break;
            default:
        }

        //UI does not like empty strings or nulls, back-end wants a null
        if (submission.approver === "0") {
            readySubmission.approver = null;
        }

        //Make sure statusID never goes lower
        if (newStatus > submission.statusID) {
            readySubmission.statusID = newStatus;
        }

        if (newStatus === 3) {
            readySubmission.executiveDate = getProperCurrentDate();
        }

        //Special case for checklists as they can self-approve.
        if (newStatus === 4) {
            readySubmission.statusID = 3;
            readySubmission.executiveDate = getProperCurrentDate();
        }

        if (formCreatingNotUpdating) {
            axios.post('/Forms/submit', readySubmission).then(res => {
                if (res.status === 200) {
                    sendNotification(newStatus);
                    let urlStr = "";

                    if (isArchive) {
                        urlStr = '/#/app/anläggningar/anläggning?objektID=';
                    } else {
                        urlStr = '/#/app/objekt/dokument?objektID=';
                    }

                    window.location.href = urlStr + submission.objectID;
                }
            }).catch(err => {
                setIsLoading(false);
                console.log('err');
                console.log(err);
            })
        } else {
            axios.post('/Forms/update?id=' + formId, readySubmission).then(res => {
                if (res.status === 200) {
                    sendNotification(newStatus);

                    let urlStr = "";

                    if (isArchive) {
                        urlStr = '/#/app/anläggningar/anläggning?objektID=';
                    } else {
                        urlStr = '/#/app/objekt/dokument?objektID=';
                    }

                    window.location.href = urlStr + submission.objectID;
                }
            }).catch(err => {
                setIsLoading(false);
                console.log('err');
                console.log(err);
            })
        }
    };

    function answerAllQuestions() {
        let submissionChange = { ...submission };

        for (var i = 0; i < template.groups.length; i++) {
            for (var j = 0; j < template.groups[i].fields.length; j++) {
                if (template.groups[i].fields[j].question.questionType === "Answers") {
                    submissionChange.results[getTotalFieldIndexWithHeaders(i, j)].answerID = 1;
                } else {
                    submissionChange.results[getTotalFieldIndexWithHeaders(i, j)].textAnswer = "2023";
                }
            }
        }

        setSubmission(submissionChange);
        setQuestionsAnswered(true);
    }

    function getTotalFieldIndexWithHeaders(groupIndex, fieldIndex) {
        let totalIndex = 0;
        if (template.groups.length > 0) {
            for (var i = 0; i < groupIndex; i++) {
                totalIndex += template.groups[i].fields.length;
            }
        }

        return totalIndex + fieldIndex;
    }

    function getTotalFieldIndex(groupIndex, fieldIndex) {
        let totalIndex = 0;
        if (template.groups.length > 0) {
            for (var i = 0; i < template.groups.length; i++) {
                for (var j = 0; j < template.groups[i].fields.length; j++) {
                    if (template.groups[i].fields[j].question.questionType !== "header") {
                        if (i === groupIndex && j === fieldIndex) {
                            return totalIndex;
                        }

                        totalIndex++;
                    }
                }
            }
        }

        return totalIndex;
    }

    function getIndexFromTotal(chosenTemplate, chosenIndex) {
        let totalIndex = -1;

        if (chosenTemplate.groups.length > 0) {
            for (var i = 0; i < chosenTemplate.groups.length; i++) {
                for (var j = 0; j < chosenTemplate.groups[i].fields.length; j++) {
                    totalIndex++;

                    if (totalIndex === chosenIndex) {
                        return [i, j]; //groupIndex, fieldIndex
                    }
                }
            }
        }
    }

    function getIndexFromTotalWithoutHeaders(chosenIndex) {
        let totalIndex = -1;

        if (template.groups.length > 0) {
            for (var i = 0; i < template.groups.length; i++) {
                for (var j = 0; j < template.groups[i].fields.length; j++) {
                    if (template.groups[i].fields[j].question.questionType !== "header") {
                        totalIndex++;
                    }

                    if (totalIndex === chosenIndex) {
                        return [i, j]; //groupIndex, fieldIndex
                    }
                }
            }
        }
    }

    function checkIfQuestionsAnswered() {
        console.log("checkIfAllowedProceed");
        console.log(submission.results);

        for (var i = 0; i < submission.results.length; i++) {
            if (submission.results[i].answerID === 0 && !submission.results[i].textAnswer) {
                console.log(i + 1 + " failed");
                setQuestionsAnswered(false);
                return;
            }

            setQuestionsAnswered(true);
        }
    }

    function handleChange(e) {
        setSubmission({
            ...submission,
            [e.target.name]: e.target.value,
        });
    }

    function handleApproverChange(e) {
        if (e.target.value === "0") {
            setApproverChosen(false);
        } else {
            setApproverChosen(true);
        }

        setSubmission({
            ...submission,
            [e.target.name]: e.target.value,
        });
    }

    function handleAnswerChange(e, groupIndex, fieldIndex, answerID) {
        if (submission.statusID < 3) {
            const totalFieldIndex = getTotalFieldIndexWithHeaders(groupIndex, fieldIndex);

            let submissionChange = { ...submission };
            submissionChange.results[totalFieldIndex].answerID = answerID;

            setSubmission(submissionChange);

            let expandedChange = { ...expanded };
            expandedChange[groupIndex][fieldIndex] = true;

            for (var i = 0; i < submissionChange.results.length; i++) { //Needs to be here or React will not detect the change in time
                if (submissionChange.results[i].answerID != null) {
                    if (submissionChange.questionType === "Answers" || submissionChange.results[i].answerID === 0) {
                        setQuestionsAnswered(false);
                        console.log(i + " failed");
                        return;
                    }
                } else if (submissionChange.results[i].textAnswer != null) {
                    if (submissionChange.results[i].textAnswer.length === 0) {
                        setQuestionsAnswered(false);
                        console.log(i + " failed");
                        return;
                    }
                } else {
                    let sortedIndices = getIndexFromTotal(template, i);

                    if (template.groups[sortedIndices[0]].fields[sortedIndices[1]].question.questionType !== "header") {
                        setQuestionsAnswered(false);
                        return;
                    }
                }
            }

            setQuestionsAnswered(true);
        }
    }

    function handleAnswerTextChange(e, groupIndex, fieldIndex) {
        let submissionChange = { ...submission };

        let expandedChange = { ...expanded };
        expandedChange[groupIndex][fieldIndex] = true;

        submissionChange.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].textAnswer = e.target.value;
        setSubmission(submissionChange);

        for (var i = 0; i < submissionChange.results.length; i++) { //Needs to be here or React will not detect the change in time
            console.log(submissionChange.results[i]);
            if (submissionChange.results[i].answerID != null) {
                console.log("choiceAnswer");
                if (submissionChange.results[i].answerID === 0) {
                    setQuestionsAnswered(false);
                    console.log(i + " failed");
                    return;
                }
            } else if (submissionChange.results[i].textAnswer != null) {
                console.log("textAnswer");
                if (submissionChange.results[i].textAnswer.length === 0) {
                    setQuestionsAnswered(false);
                    console.log(i + " failed");
                    return;
                }
            } else {
                let sortedIndices = getIndexFromTotal(template, i);

                if (template.groups[sortedIndices[0]].fields[sortedIndices[1]].question.questionType !== "header") {
                    setQuestionsAnswered(false);
                    return;
                }
            }
        }

        setQuestionsAnswered(true);
    }

    function handleResultsChange(e, questionID) {
        let submissionChange = { ...submission };

        let questionIndex = submissionChange.results.findIndex(result => result.questionID === questionID);
        submissionChange.results[questionIndex][e.target.name] = e.target.value;

        setSubmission(submissionChange);
    }
    
    function handleRiskChange(e) {
        if (e.target.textContent) {
            const type = e.target.textContent.charAt(0);
            const level = e.target.textContent.charAt(1);

            switch (type) {
                case "S":
                    break;
                case "K":
                    break;
                case "R":
                    break;
                default:
            }
        }
    }

    function handleSetNewRisk(e) {
        setRiskLevel({ ...riskLevel, [e.target.name]: e.target.value });
    }

    function handleCommentChange(e, groupIndex, fieldIndex) {
        let submissionChange = { ...submission };

        submissionChange.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].comment = e.target.value;
        setSubmission(submissionChange);
    }

    function handleMeasureChange(e, groupIndex, fieldIndex) {
        let submissionChange = { ...submission };

        submissionChange.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].addAsMeasure = !submissionChange.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].addAsMeasure;
        setSubmission(submissionChange);
    }

    function friendlyFindUser(id) {
        let temp = users.find(user => user.id === id);

        if (typeof temp === "undefined") {
            return "";
        }

        return temp;
    }

    function getRiskTextAndColor(accidentChance, accidentConsequence) {
        const totalRisk = accidentChance * accidentConsequence;
        let text, subtext, color;

        if (totalRisk > 9) {
            text = "hög risk";
            subtext = "Kräver direkta åtgärder";
            color = "#ff0101";
        } else if (totalRisk > 4) {
            text = "medel risk";
            subtext = "Utreds, åtgärdas på sikt";
            color = "#ffff00";
        } else if (totalRisk === 4 && (accidentChance === 1 || accidentConsequence === 1)) { //Special case: 2*2 means green but 1*4 and 4*1 means yellow
            text = "medel risk";
            subtext = "Utreds, åtgärdas på sikt";
            color = "#ffff00";
        } else {
            text = "låg risk";
            subtext = "Acceptabel risk";
            color = "#96f905";
        }

        return (
            <>
                <Typography><span style={{ backgroundColor: color }}><strong>Riskvärde: </strong>{<span>{totalRisk}, {text}</span>}</span></Typography>
                <Typography>{subtext}</Typography>
            </>
        )
    }

    function handleParticipantChange(e, participantIndex) {
        let tempParticipantsChange = [...tempParticipants];
        let newParti = null;

        if (e.target.name === "userID") {
            let tempUser = users.find(user => user.id === e.target.value);

            newParti = {
                name: tempUser.fullName ? tempUser.fullName : "",
                email: tempUser.email ? tempUser.email : "",
                title: tempUser.title ? tempUser.title : "",
                userID: e.target.value
            };

        } else {
            newParti = { ...tempParticipantsChange[participantIndex], [e.target.name]: e.target.value };
        }

        tempParticipantsChange[participantIndex] = newParti;

        console.log("tempParticipantsChange");
        console.log(tempParticipantsChange);
        setTempParticipants(tempParticipantsChange);
    }

    function isUserTaken(userID) {
        return tempParticipants.some(tempParti => tempParti.userID === userID);
    }

    function getUntakenUsers(participantID) {
        return users.filter(user => (user.id === participantID || !isUserTaken(user.id)));
    }

    function participantsModalClearRow(rowIndex) {
        let tempParticipantsChange = [...tempParticipants];
        tempParticipantsChange[rowIndex].name = "";
        tempParticipantsChange[rowIndex].title = "";
        tempParticipantsChange[rowIndex].email = "";
        tempParticipantsChange[rowIndex].userID = "";

        setTempParticipants(tempParticipantsChange);
    }

    function getRowFromDataTable(row, column) {
        return muiTable.current.props.data[row][column];
    }

    function getSelectedRows() {
        let selectedRows = [];

        for (var i = 0; i < tempBasisList.length; i++) {
            if (tempBasisList[i] != null) {
                selectedRows.push(i);
            }
        }

        return selectedRows;
    }

    function sendNotification(newStatus) {
        let msg;

        switch (newStatus) {
            case 2:
                msg = "Dokument inskickat!"
                break;
            case 3:
                msg = "Dokument godkänt!"
                break;
            default:
                msg = "Dokument uppdaterat!"
                break;
        }

        const componentProps = {
            type: "feedback",
            message: msg,
            variant: "contained",
            color: "success"
        };
        const options = {
            type: "info",
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
            timeOut: 1000
        };
        return toast(
            <Notification
                {...componentProps}
                className={classes.notificationComponent}
            />,
            options
        );
    }

    function removeImage(e, resultIndex, imageIndex) {
        let submissionChange = { ...submission };
        let imageList = submissionChange.results[resultIndex].images;
        submissionChange.results[resultIndex].images = imageList.filter((img, index) => index !== imageIndex)
        setSubmission(submissionChange);
    }

    function getStatusText() {
        switch (submission.statusID) {
            case 1:
                return "Arbetskopia";
            case 2:
                return "Pågående";
            case 3:
                return "Godkänd";
            default:
                return "Okänd";
        }
    }

    function handleNextWithPrompt(newStatus) {
        switch (newStatus) {
            case 2:
                if (window.confirm("Är du säker på att du vill skicka till ansvarig?\nDu kommer inte kunna redigera ditt formulär tills det är godkänt.")) {
                    handleNext(newStatus);
                }
                break;
            case 3:
                if (window.confirm("Är du säker på att du vill godkänna dokumentet?\nDu kommer inte kunna ta tillbaka ditt godkännande, och ingen kommer att kunna redigera formuläret efter godkännandet.")) {
                    handleNext(newStatus);
                }
                break;
            case 4:
                if (window.confirm("Är du säker på att du vill godkänna dokumentet?\nDu kommer inte kunna ta tillbaka ditt godkännande, och ingen kommer att kunna redigera formuläret efter godkännandet.")) {
                    handleNext(newStatus);
                }
                break;
            default:
        }
    }

    function handleExpandedToggle(groupIndex, fieldIndex) {
        let expandedChange = [...expanded];

        expandedChange[groupIndex][fieldIndex] = !expanded[groupIndex][fieldIndex];

        setExpanded(expandedChange);
    }

    function getYearSpan(yearSpan) {
        const currentYear = currentDate.getFullYear();
        let yearArray = [];

        for (var i = 0; i <= yearSpan; i++) {
            yearArray.push((currentYear + i).toString());
        }

        return yearArray;
    }

    function renderGroup(groupObject, groupIndex) {
        return (
            <Grid key={groupIndex} item xs={12}>
                <Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} className={classes.noBreakWhenPrint} variant='h3'>
                    {groupObject.name}
                </Typography>
                {groupObject.fields.map((field, fieldIndex) => (
                    (field.question.questionType !== "header" ? <Accordion
                        expanded={expanded[groupIndex][fieldIndex]} key={groupIndex + "." + fieldIndex}
                        className={classes.noBreakWhenPrint}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={field.question.hideComment ? classes.hideAccordionArrow + " " + classes.noPointer : classes.noPointer + " " + classes.hideAccordionArrowInPrint}
                            onClick={() => field.question.hideComment ? null : handleExpandedToggle(groupIndex, fieldIndex)}
                        >
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>{field.question.questionType !== "header" ? (getTotalFieldIndex(groupIndex, fieldIndex) + 1) + "." : ""} {field.question.text}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: "0.9rem" }} >{field.question.description}</Typography>
                                    </Grid>
                                    <Grid item xs={12} onFocus={(event) => event.stopPropagation()} onClick={(event) => event.stopPropagation()}>
                                        {(field.question.questionType === "Answers" || field.question.questionType === null) &&
                                            <FormControl disabled={submission.statusID === 3}>
                                                <RadioGroup
                                                    name="radio-buttons-group"
                                                    value={submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].answerID}
                                                    style={{ flexDirection: "row" }}
                                                >
                                                    {field.question.answers.map(answer => (
                                                        <FormControlLabel key={answer.id} value={answer.id} onClick={e => handleAnswerChange(e, groupIndex, fieldIndex, answer.id)} control={<Radio style={{ color: "#17a506" }} />} label={answer.text} />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        }
                                        {(field.question.questionType === "Text" && submission.statusID !== 3) &&
                                            <TextField
                                                label={field.question.questionLegend}
                                                name="answer"
                                                variant="outlined"
                                                style={{ margin: 15, width: "90%" }}
                                                helperText={field.question.helperText}
                                                value={submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].textAnswer}
                                                onChange={e => handleAnswerTextChange(e, groupIndex, fieldIndex)}
                                                disabled={submission.statusID === 3}
                                            />
                                        }
                                        {(field.question.questionType === "year-dropdown" && submission.statusID !== 3) &&
                                            <Select
                                                value={submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].textAnswer}
                                                name="answer"
                                                onChange={e => handleAnswerTextChange(e, groupIndex, fieldIndex)}
                                                label="År"
                                                displayEmpty
                                                style={{ padding: "14px", marginLeft: "16px" }}
                                            >
                                                {
                                                    getYearSpan(30).map(
                                                        year => {
                                                            return (
                                                                <MenuItem key={year} value={year}>{year}</MenuItem>
                                                            )
                                                        }
                                                    )
                                                }

                                                {/*<MenuItem key={1} value={"1"}>1 år</MenuItem>
                                                <MenuItem key={2} value={"2"}>2 år</MenuItem>
                                                <MenuItem key={3} value={"3"}>3 år</MenuItem>
                                                <MenuItem key={4} value={"4"}>4 år</MenuItem>
                                                <MenuItem key={5} value={"5"}>5 år</MenuItem>
                                                <MenuItem key={6} value={"6"}>6 år</MenuItem>
                                                <MenuItem key={7} value={"7"}>7 år</MenuItem>
                                                <MenuItem key={8} value={"8"}>8 år</MenuItem>
                                                <MenuItem key={9} value={"9"}>9 år</MenuItem>
                                                <MenuItem key={10} value={"10"}>10 år</MenuItem>
                                                <MenuItem key={11} value={"11"}>11 år</MenuItem>
                                                <MenuItem key={12} value={"12"}>12 år</MenuItem>*/}
                                            </Select>
                                        }
                                        {(field.question.questionType !== "Answers" && submission.statusID === 3) &&
                                            <Typography style={{ padding: "8px", wordBreak: "break-word" }}>
                                                {submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].textAnswer}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        {!field.question.hideComment &&
                            <AccordionDetails className={classes.QuestionUnderline}>
                                <Grid item xs={4}>
                                    {submission.statusID < 3 && <>
                                        <TextField
                                            id={"commentNo:" + groupIndex + "," + fieldIndex}
                                            value={submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].comment}
                                            className={classes.noPrint}
                                            label="Kommentar"
                                            name={"commentNo:" + groupIndex + "," + fieldIndex}
                                            onChange={e => handleCommentChange(e, groupIndex, fieldIndex)}
                                            variant="outlined"
                                            style={{ margin: "12px 0", width: "80%" }}
                                            multiline
                                        />
                                        <div className={classes.noPrint}>
                                            <GreenCheckbox
                                                checked={submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].addAsMeasure}
                                                onClick={e => handleMeasureChange(e, groupIndex, fieldIndex)}
                                            />
                                            <Typography size={'sm'} style={{ marginBottom: 30 }} className={classes.measureText}>
                                                Lägg till som åtgärdspunkt
                                            </Typography>
                                        </div>
                                    </>}
                                    {submission.statusID === 3 && submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].comment.length > 0 && <div>
                                        <Typography style={{ padding: "8px", wordBreak: "break-word" }}>
                                            {submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].addAsMeasure &&
                                                <GreenCheckbox
                                                    checked={true}
                                                    style={{ cursor: "default", paddingTop: "5px" }}
                                                />
                                            }
                                            Kommentar: {submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].comment}
                                        </Typography>
                                    </div>}
                                </Grid>
                                <Grid container item xs={4}>
                                    <>
                                        <Grid item xs={12}>
                                            {submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].images.map((image, index) => (
                                                <div className={classes.imgContainer} key={index}>
                                                    <img className={classes.thumbNail + " image"} src={image.filePath} />
                                                    {
                                                        submission.statusID < 3 &&
                                                        <span
                                                            onClick={e => removeImage(e, getTotalFieldIndexWithHeaders(groupIndex, fieldIndex), index)}
                                                            className={classes.imgCross + " " + classes.noPrint}
                                                        >
                                                            X
                                                        </span>
                                                    }
                                                    <div className={classes.imgMiddle + " middle"}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => imageModalOpenNow(image)}
                                                            style={{ height: "50%" }}
                                                        >
                                                            Förstora
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Grid>
                                        {submission.statusID < 3 &&
                                            <Grid item xs={12} className={classes.noPrint}>
                                                <label
                                                    className={classes.uploadLabel}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <Image className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                    {'Ladda upp bild'}
                                                    <input style={{ display: 'none' }} accept="image/jpeg, image/png, .pdf, .doc, .docx, .txt, .xls, .xlsx" type="file" ref={fileInput} onChange={e => handleFile(e, field.question.id)} />
                                                </label>
                                                <Typography
                                                    size={'sm'}
                                                    style={{ marginBottom: 30 }}
                                                >
                                                    Accepterade filformat: .PNG, .JPG/.JPEG
                                                </Typography>
                                            </Grid>}
                                    </>
                                    {/*<Typography style={{ display: "none", verticalAlign: "middle" }}><RemoveCircleOutline style={{ verticalAlign: "middle", color: "red" }} /> Ta bort bild</Typography>*/}
                                </Grid>
                                {template && (documentTypeId === 8 || documentTypeId === 18 || documentTypeId === 19) && <Grid item xs={4}>
                                    <div>
                                        {submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].accidentChance > 0 ?
                                            <>
                                                {submission.statusID < 3 &&
                                                    <label
                                                        className={classes.uploadLabel + " " + classes.noPrint}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => riskMatrixModalOpenNow(field.question.id)}
                                                    >
                                                        <RiskMatrix className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                        {'Ändra riskmatris'}
                                                    </label>
                                                }
                                                {getRiskTextAndColor(submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].accidentChance, submission.results[getTotalFieldIndexWithHeaders(groupIndex, fieldIndex)].accidentConsequence)}
                                            </>
                                            :
                                            (
                                                <>
                                                    {submission.statusID < 3 && <>
                                                        <label
                                                            className={classes.uploadLabel + " " + classes.noPrint}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => riskMatrixModalOpenNow(field.question.id)}
                                                        >
                                                            <RiskMatrix className={classes.TextIcons} style={{ color: "white", marginRight: "4px" }} />
                                                            {'Fyll i riskmatris'}
                                                        </label>
                                                        <Typography
                                                            size={'sm'}
                                                            style={{ marginBottom: 30 }}
                                                            className={classes.noPrint}
                                                        >
                                                            Ange sannolikhet och konsekvens för risken
                                                        </Typography>
                                                    </>}
                                                </>
                                            )
                                        }
                                    </div>
                                </Grid>}
                            </AccordionDetails>}
                    </Accordion>
                        :
                        <Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} className={classes.noBreakWhenPrint} variant='h3'>
                            {field.question.text}
                        </Typography>)
                ))
                }
            </Grid >
        );
    }

    useEffect(() => {
        let lastLocation = history.location;

        const unblock = history.block((location, action) => {
            if (submission.statusID !== 3 && action === "POP" && location.pathname !== lastLocation.pathname) {

                if (shouldConfirmLeave) {
                    const confirmLeave = window.confirm("Dina osparade ändringar kommer att försvinna. Är du säker?");
                    if (!confirmLeave) {
                        return false;
                    }
                }
            }

            // Update last location for future navigation checks
            lastLocation = location;
            return true; // Allow navigation otherwise
        });

        return () => {
            unblock();
        };
    }, [history, submission, shouldConfirmLeave]);

    if (!template || template.name === null) {
        return (<CircularProgress />);
    }

    return (
        <Grid container spacing={3} className={classes.hideAllElseInPrint}>
            {/*<Typography style={{ margin: "20px 0 10px 40px", color: "#17a506" }} variant='h3'>*/}
            {/*    {submission.name}*/}
            {/*</Typography>*/}
            <Grid item xs={12} className={classes.fullHeightWhenPrint}>
                <Widget disableWidgetMenu className={classes.disableWidgetBoxShadow}>
                    <Grid container>
                        <Grid container item xs={8}>
                            <Grid container item spacing={3} xs={12}>
                                <Grid container item xs={12}>
                                    <img
                                        src={miniLogo}
                                        alt={"Checksoft"}
                                        style={{ width: "29px", height: "29px" }}
                                        onClick={e => { if (e.ctrlKey) { answerAllQuestions() } }}
                                    />
                                    <Typography variant='h4'>
                                        <strong><span>{submission && submission.name}</span></strong>
                                    </Typography>
                                    <br />
                                </Grid>
                            </Grid>
                            {unauthorized && <Grid item xs={12}>
                                <Typography>
                                    <span style={{ color: "red" }}>
                                        OBS! Behörighet saknas! Om du inte är ansvarig att godkänna det här dokumentet så kan du för tillfället inte inspektera det.
                                    </span>
                                </Typography>
                            </Grid>}

                            <Grid container item spacing={3} xs={12}>
                                {object && <>
                                    <Grid item xs={12}>
                                        <table>
                                            {permaSavedInfo ?
                                                <tbody>
                                                    <tr>
                                                        <td className={classes.TableLeft}>Status: </td>
                                                        <td className={classes.TableRight}>{permaSavedInfo.formStatusName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.TableLeft}>Mall: </td>
                                                        <td className={classes.TableRight}>{permaSavedInfo.templateName}</td>
                                                    </tr>
                                                    {(template.documentType.id !== 18 && template.documentType.id !== 19) &&
                                                        <>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Objektnamn: </td>
                                                                <td className={classes.TableRight}>{object && object.objectName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Objektnummer: </td>
                                                                <td className={classes.TableRight}>{object && object.objectNr}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Typ: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.typeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Kategori: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.typeMainTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Tillverkare: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.manufacturer}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Tillverkningsnr: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.manufacturerNr}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Registrering: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.regNr}</td>
                                                            </tr>
                                                            {template.documentType.id === 8 &&
                                                                <tr>
                                                                    <td className={classes.TableLeft}>Övervakningsintervall: </td>
                                                                    <td className={classes.TableRight}>{permaSavedInfo.monitoringInterval}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td className={classes.TableLeft}>Uppställ.plats: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.placement}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Platsnr: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.objectNr}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Kontrollklass: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.controlClassName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className={classes.TableLeft}>Kund/Avdelning: </td>
                                                                <td className={classes.TableRight}>{permaSavedInfo.customerName} {permaSavedInfo.sectionName && " / " + permaSavedInfo.sectionName}</td>
                                                            </tr>
                                                            {template.documentType.id === 8 &&
                                                                <tr>
                                                                    <td className={classes.TableLeft}>FLT-intervall: </td>
                                                                    <td className={classes.TableRight}>{permaSavedInfo.fltInterval}</td>
                                                                </tr>
                                                            }
                                                        </>}
                                                    <tr>
                                                        <td className={classes.TableLeft}>Anläggning: </td>
                                                        <td className={classes.TableRight}>{permaSavedInfo.facilityName}</td>
                                                    </tr>

                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td className={classes.TableLeft}>Status: </td>
                                                        <td className={classes.TableRight}>{getStatusText()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={classes.TableLeft}>Mall: </td>
                                                        <td className={classes.TableRight}>{template && template.name}</td>
                                                    </tr>
                                                    {(template.documentType.id !== 18 && template.documentType.id !== 19) && <>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Typ: </td>
                                                            <td className={classes.TableRight}>{object.type.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Kategori: </td>
                                                            <td className={classes.TableRight}>{object.type.mainType.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Tillverkare: </td>
                                                            <td className={classes.TableRight}>{object.manufacturer}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Tillverkningsnr: </td>
                                                            <td className={classes.TableRight}>{object.manufacturerNr}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Registrering: </td>
                                                            <td className={classes.TableRight}>{object.regNr}</td>
                                                        </tr>
                                                        {template.documentType.id === 8 &&
                                                            <tr>
                                                                <td className={classes.TableLeft}>Övervakningsintervall: </td>
                                                                <td className={classes.TableRight}>{docObj.monitoringInterval}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className={classes.TableLeft}>Uppställ.plats: </td>
                                                            <td className={classes.TableRight}>{object.placement}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Platsnr: </td>
                                                            <td className={classes.TableRight}>{object.objectNr}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Kontrollklass: </td>
                                                            <td className={classes.TableRight}>{findInObjectData('classifications', object.controlClass, 'name')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={classes.TableLeft}>Kund/Avdelning: </td>
                                                            <td className={classes.TableRight}>{object.customer ? object.customer.name : "Ingen kund"} {object.customer && object.section && <>/</>} {object.section && object.section.name}</td>
                                                        </tr>
                                                        {template.documentType.id === 8 &&
                                                            <tr>
                                                                <td className={classes.TableLeft}>FLT-intervall: </td>
                                                                <td className={classes.TableRight}>{findInObjectData('fltIntervals', object.fltInterval, 'name')}</td>
                                                            </tr>
                                                        }
                                                    </>}
                                                    <tr>
                                                        <td className={classes.TableLeft}>Anläggning: </td>
                                                        <td className={classes.TableRight}>{object.facility ? object.facility.name : "Ingen anläggning"}</td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </Grid>
                                </>}

                                <br />
                                <br />

                                <Grid item xs={12}>
                                    <table>
                                        <tbody>
                                            {submission.statusID === 3 && <tr>
                                                <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>
                                                    <br />
                                                    <a href={"/print/" + formId} target="_blank">
                                                        <label
                                                            className={classes.textDescriptionLabel + " " + classes.noPrint}
                                                            style={{ cursor: 'pointer', position: "relative", bottom: "22px" }}
                                                        >
                                                            {'Skriv ut'}
                                                        </label>
                                                    </a>
                                                </td>
                                            </tr>}

                                            <tr>
                                                <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>
                                                    Deltagare:
                                                    <br />
                                                    {submission.statusID < 3 &&
                                                        <label
                                                            className={classes.textDescriptionLabel + " " + classes.noPrint}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => participantsModalOpenNow()}
                                                        >
                                                            {'Välj deltagare'}
                                                        </label>
                                                    }
                                                </td>
                                                <td style={{ paddingTop: "10px" }} className={classes.TableRight}>
                                                    {permaSavedInfo ?
                                                        permaSavedInfo.form.participants.map(participant =>
                                                            <React.Fragment key={participant.email}>
                                                                {(participant.title ? participant.title + ": " : "") + participant.name + (participant.email ? " - " + participant.email : "")}
                                                                <br />
                                                            </React.Fragment>
                                                        )
                                                        :
                                                        submission.participants.map(participant =>
                                                            <React.Fragment key={participant.email}>
                                                                {(participant.title ? participant.title + ": " : "") + participant.name + (participant.email ? " - " + participant.email : "")}
                                                                <br />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <br />
                                            {documentTypeId !== 18 && documentTypeId !== 19 && <>
                                                <tr>
                                                    <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>
                                                        Underlag:
                                                        <br />
                                                        {submission.statusID < 3 && <label
                                                            className={classes.textDescriptionLabel + " " + classes.noPrint}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => basisModalOpenNow()}
                                                        >
                                                            {'Välj underlag'}
                                                        </label>
                                                        }
                                                    </td>
                                                    <td style={{ paddingTop: "10px" }} className={classes.TableRight}>
                                                        {permaSavedInfo ?
                                                            permaSavedInfo.form.bases.map(tempBasisObj =>
                                                                <React.Fragment key={tempBasisObj.id}>
                                                                    {tempBasisObj.documentName + " (" + tempBasisObj.documentTypeName + ")" + (tempBasisObj.executiveDate ? " - " + cleanseDateStringFromT(tempBasisObj.executiveDate) : "")}
                                                                    < br />
                                                                </React.Fragment>
                                                            )
                                                            :
                                                            tempBasisList.filter(tempBasisObj => tempBasisObj != null).map(tempBasisObj =>
                                                                <React.Fragment key={tempBasisObj.id}>
                                                                    {tempBasisObj.documentName + " (" + tempBasisObj.documentTypeName + ")" + (tempBasisObj.executiveDate ? " - " + cleanseDateStringFromT(tempBasisObj.executiveDate) : "")}
                                                                    < br />
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                {submission.statusID < 3 && <tr>
                                                    <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>
                                                        <br />
                                                        {isArchive ?
                                                            <a href={"/#/app/anläggningar/anläggning?objektID=" + object.id} target="_blank">
                                                                <label
                                                                    className={classes.textDescriptionLabel + " " + classes.noPrint}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {'Öppna anläggning'}
                                                                </label>
                                                            </a>
                                                            :
                                                            <a href={"/#/app/objekt/dokument?objektID=" + object.id} target="_blank">
                                                                <label
                                                                    className={classes.textDescriptionLabel + " " + classes.noPrint}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {'Öppna dokumentsida'}
                                                                </label>
                                                            </a>
                                                        }
                                                    </td>
                                                </tr>}
                                            </>}
                                        </tbody>
                                    </table>

                                    <br />
                                    {(objectData.fltIntervals && template && template.documentType && template.documentType.id === 8) && <>
                                        {submission.statusID < 3 ? <>
                                            <Grid item>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{ marginBottom: 35 }}
                                                    disabled={submission.statusID === 3}
                                                >
                                                    <InputLabel>
                                                        FLT-intervall
                                                    </InputLabel>
                                                    <Select
                                                        name="fltInterval"
                                                        onChange={e => setFltInterval(e.target.value)}
                                                        label="FLT-intervall"
                                                        value={fltInterval}
                                                        disabled={submission.statusID === 3}
                                                    >
                                                        {
                                                            objectData.fltIntervals && objectData.fltIntervals.map(
                                                                fltI => {
                                                                    if (fltI.value >= 0) {
                                                                        return (
                                                                            <MenuItem key={fltI.name} value={fltI.value}>{fltI.name}</MenuItem>
                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                    <FormHelperText>
                                                        Välj vilket intervall Fortlöpande Tillsyn har
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{ marginBottom: 35 }}
                                                    disabled={submission.statusID === 3}
                                                >
                                                    <InputLabel>
                                                        Livslängdsjournal behövs
                                                    </InputLabel>
                                                    <Select
                                                        name="journalIsNeeded"
                                                        onChange={e => setJournalIsNeeded(e.target.value)}
                                                        label="Livslängdsjournal behövs"
                                                        value={journalIsNeeded}
                                                    >
                                                        {
                                                            objectData.journalIsNeeded && objectData.journalIsNeeded.map(
                                                                journalIsNeededChoice => {
                                                                    if (journalIsNeededChoice.value >= 0) {
                                                                        return (
                                                                            <MenuItem key={journalIsNeededChoice.name} value={journalIsNeededChoice.value}>{journalIsNeededChoice.name}</MenuItem>
                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                    <FormHelperText>
                                                        Välj om livslängdsjournal behövs eller inte
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </>
                                            :
                                            <>
                                                <table>
                                                    <tbody>
                                                        {/*<tr>*/}
                                                        {/*    <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>*/}
                                                        {/*        FLT-Intervall:*/}
                                                        {/*    </td>*/}
                                                        {/*    <td className={classes.TableRight}>*/}
                                                        {/*        {permaSavedInfo ? permaSavedInfo.fltInterval : findInObjectData('fltIntervals', fltInterval, 'name')}*/}
                                                        {/*    </td>*/}
                                                        {/*</tr>*/}
                                                        <tr>
                                                            <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>
                                                                Livslängdsjournal behövs:
                                                            </td>
                                                            <td className={classes.TableRight}>
                                                                {permaSavedInfo ? permaSavedInfo.journalIsNeeded : findInObjectData('journalIsNeeded', journalIsNeeded, 'name')}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br />
                                            </>}
                                    </>}

                                    {documentTypeId === 7 && <Grid item>
                                        {submission.statusID < 3 ?
                                            <TextField
                                                disabled
                                                value={nextFLTDate}
                                                className={classes.noPrint}
                                                onChange={e => { setNextFLTDate(e.target.value) }}
                                                name="dateOfNextFlt"
                                                variant="outlined"
                                                style={{ marginBottom: 30 }}
                                                helperText="Datum för nästa FLT"
                                                type="date"
                                            />
                                            :
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: "top" }} className={classes.TableLeft}>
                                                            Nästa FLT:
                                                        </td>
                                                        <td className={classes.TableRight}>
                                                            {permaSavedInfo ? permaSavedInfo.dateOfNextFlt : cleanseDateStringFromT(nextFLTDate)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>}
                                    </Grid>}
                                    {documentTypeId !== 18 && documentTypeId !== 19 && documentTypeId !== 7 && <>
                                        {submission.statusID < 3 && <FormControl
                                            variant="outlined"
                                            onChange={handleChange}
                                            className={classes.noPrint}
                                            style={{ marginBottom: 35 }}
                                            disabled={submission.statusID === 3}
                                        >
                                            <InputLabel>
                                                Ansvarig, namn
                                            </InputLabel>
                                            <Select
                                                name="approver"
                                                label="Ansvarig, namn"
                                                onChange={handleApproverChange}
                                                value={submission.approver}
                                                defaultValue={"0"}
                                            >
                                                <MenuItem value="0">Ingen ansvarig vald</MenuItem>
                                                {
                                                    users.map(user =>
                                                        <MenuItem key={user.id} value={user.id}>{user.fullName}</MenuItem>
                                                    )}
                                            </Select>
                                            <FormHelperText>
                                                Välj vem som är ansvarig för ärendet
                                            </FormHelperText>
                                        </FormControl>}

                                        {submission.statusID === 3 ? <>
                                            {!sigImgAddress !== null &&
                                                <img src={sigImgAddress} style={{ maxWidth: "300px", maxHeight: "200px", display: "block" }} />}
                                            <div style={{ display: "flex", justifyContent: "space-evenly", width: "500px" }}>
                                                <div>
                                                    {submission.approver !== "0" ?
                                                        <Typography>Signerat av: {permaSavedInfo ? permaSavedInfo.approverFullName : friendlyFindUser(submission.approver).fullName}</Typography>
                                                        :
                                                        <Typography>Ingen ansvarig vald</Typography>
                                                    }
                                                </div>
                                                {docObj && docObj.executiveDate &&
                                                    <Typography>Datum: {permaSavedInfo ? permaSavedInfo.approvedDate : cleanseDateStringFromT(docObj.executiveDate)}</Typography>}
                                            </div>
                                        </> :
                                            <>
                                                <Typography>Ansvarig signatur</Typography>
                                                <div className={classes.SignLine}></div>
                                                <br />
                                                <Typography>Datum</Typography>
                                                <div className={classes.SignLine}></div>
                                            </>}
                                        <br />
                                        <br />
                                    </>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            {
                                mainImage.length > 0 &&
                                <img src={mainImage} className={classes.mainImage} alt="Översiktsbild" />
                            }
                        </Grid>
                    </Grid>
                </Widget>
            </Grid >

            {
                (template && template.groups && expanded.length > 0) && template.groups.map((group, index) => (
                    renderGroup(group, index)
                ))
            }

            <img src={riskMatrixImage} className={classes.modalImage} style={{ display: "none" }} /> {/*Make sure the image is ready before the modal is visible*/}
            <Modal //Participants modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalContent}
                open={participantsModalOpen}
                onClose={() => participantsModalCloseNow(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={participantsModalOpen}>
                    <div className={classes.modalPaper}>
                        <Grid container>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography style={{ color: "#17a506" }} variant='h3'>
                                    Skriv in eller välj deltagare
                                </Typography>
                            </Grid>
                        </Grid>

                        {tempParticipants.map((participant, index) =>
                            <Grid container key={index} justifyContent="center">
                                <Grid container item xs={1} alignItems="center" justifyContent="center">
                                    <Typography>
                                        Deltagare {index + 1}:
                                    </Typography>
                                </Grid>

                                <Grid container item xs={2}>
                                    <TextField
                                        id={"participant" + index + "name"}
                                        label="Namn"
                                        value={tempParticipants[index].name}
                                        onChange={e => handleParticipantChange(e, index)}
                                        name={"name"}
                                        variant="outlined"
                                        style={{ margin: "12px 0", width: "80%" }}
                                        disabled={tempParticipants[index].userID}
                                    />
                                </Grid>

                                <Grid container item xs={2}>
                                    <TextField
                                        id={"participant" + index + "email"}
                                        label="E-post"
                                        value={tempParticipants[index].email}
                                        onChange={e => handleParticipantChange(e, index)}
                                        name={"email"}
                                        variant="outlined"
                                        style={{ margin: "12px 0", width: "80%" }}
                                        disabled={tempParticipants[index].userID}
                                    />
                                </Grid>

                                <Grid container item xs={2}>
                                    <TextField
                                        id={"participant" + index + "role"}
                                        label="Roll/Titel"
                                        value={tempParticipants[index].title}
                                        onChange={e => handleParticipantChange(e, index)}
                                        name={"title"}
                                        variant="outlined"
                                        style={{ margin: "12px 0", width: "80%" }}
                                    />
                                </Grid>

                                <Grid container item xs={3}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ margin: "12px 0", width: "80%" }}
                                    >
                                        <InputLabel>
                                            Välj deltagare från lista
                                        </InputLabel>
                                        <Select
                                            name="userID"
                                            label="Välj deltagare från lista"
                                            value={tempParticipants[index].userID}
                                            onChange={e => handleParticipantChange(e, index)}
                                        >
                                            {getUntakenUsers(tempParticipants[index].userID).length > 0 &&
                                                (currentUser => <MenuItem value={currentUser.id}>{currentUser.email}</MenuItem>)}

                                            {getUntakenUsers(tempParticipants[index].userID).length > 0 &&
                                                (getUntakenUsers(tempParticipants[index].userID).map(currentUser => <MenuItem key={currentUser.id} value={currentUser.id}>{currentUser.email}</MenuItem>))}

                                            {getUntakenUsers(tempParticipants[index].userID).length === 0 &&
                                                (<MenuItem value={""}>{"Inga användare att välja"}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid container item xs={1} alignItems={"center"}>
                                    {(tempParticipants[index].email.length > 0 || tempParticipants[index].title.length > 0 || tempParticipants[index].name.length > 0) &&
                                        <Button
                                            variant="contained"
                                            color="red"
                                            onClick={() => participantsModalClearRow(index)}
                                            style={{ height: "50%" }}
                                        >
                                            Töm
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        )}

                        {showEmailNecessaryText && <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
                            <Typography style={{ color: "red" }} >E-post är obligatoriskt för deltagare</Typography>
                        </Grid>}

                        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
                            <Grid container item xs={3} justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => participantsModalCloseNow(true)}
                                >
                                    Spara
                                </Button>
                            </Grid>

                            <Grid container item xs={3} justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => participantsModalCloseNow(false)}
                                >
                                    Avbryt
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>

            <Modal //Basis modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalContent}
                open={basisModalOpen}
                onClose={() => basisModalCloseNow(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={basisModalOpen}>
                    <div className={classes.modalPaper} style={{ maxWidth: "1280px", maxHeight: "800px" }}>
                        <MuiThemeProvider theme={getMuiTheme()}> {object &&
                            <MUIDataTable
                                title="Välj underlag"
                                columns={[
                                    {
                                        name: "Name",
                                    },
                                    {
                                        name: "Typ"
                                    },
                                    {
                                        name: "Utfärdat"
                                    },
                                    {
                                        name: "Har fil"
                                    },
                                    {
                                        name: "id",
                                        options: {
                                            display: "false",
                                            filter: false,
                                            viewColumns: false
                                        }
                                    },
                                ]}
                                data={docObj ?
                                    object.documents.filter(currentDocument => docObj.id !== currentDocument.id).map(
                                        (b, index) => {
                                            return [
                                                b.documentName,
                                                b.type.name,
                                                cleanseDateStringFromT(b.executiveDate),
                                                b.documentFileDto ? "Ja" : "Nej",
                                                b.id
                                            ]
                                        })
                                    :
                                    object.documents.map(
                                        (b, index) => {
                                            return [
                                                b.documentName,
                                                b.type.name,
                                                cleanseDateStringFromT(b.executiveDate),
                                                b.documentFileDto ? "Ja" : "Nej",
                                                b.id
                                            ]
                                        })
                                }

                                options={modalTableOptions(null, getSelectedRows)}
                                ref={muiTable}
                            />}
                        </MuiThemeProvider>
                        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
                            <Grid container item xs={3} justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => basisModalCloseNow(true)}
                                >
                                    Spara
                                </Button>
                            </Grid>

                            <Grid container item xs={3} justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => basisModalCloseNow(false)}
                                >
                                    Avbryt
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>

            <Modal //Risk matrix modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalContent}
                open={riskMatrixModalOpen}
                onClose={() => riskMatrixModalCloseNow(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={riskMatrixModalOpen}>
                    <div className={classes.modalPaper}>
                        <div>
                            <img src={riskMatrixImage} className={classes.modalImage} />
                        </div>
                        <Grid container>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ padding: "10px", display: "flex", justifyContent: "space-around" }}>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: "30%" }}
                                    >
                                        <InputLabel>
                                            Sannolikhetsnivå
                                        </InputLabel>
                                        <Select
                                            name="accidentChance"
                                            label="Sannolikhetsnivå"
                                            value={riskLevel.accidentChance}
                                            onChange={e => handleSetNewRisk(e)}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: "30%" }}
                                    >
                                        <InputLabel>
                                            Konsekvensnivå
                                        </InputLabel>
                                        <Select
                                            name="accidentConsequence"
                                            label="Konsekvensnivå"
                                            value={riskLevel.accidentConsequence}
                                            onChange={e => handleSetNewRisk(e)}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div style={{ textAlign: "center" }}>
                                        {getRiskTextAndColor(riskLevel.accidentChance, riskLevel.accidentConsequence)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justifyContent="space-between">
                                    <Grid container item xs={4} justifyContent="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => riskMatrixModalCloseNow(true)}
                                        >
                                            Spara
                                        </Button>
                                    </Grid>

                                    <Grid container item xs={4} justifyContent="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => riskMatrixModalCloseNow(false)}
                                        >
                                            Avbryt
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>

            <Modal //Enlarged image modal
                className={classes.modalContent}
                open={imageModalOpen}
                onClose={() => imageModalCloseNow()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={imageModalOpen} onClick={() => imageModalCloseNow()}>
                    <div className={classes.modalPaper} style={{ maxWidth: "1024px", maxHeight: "1024px" }}>
                        <img src={modalEnlargedImage} style={{ maxWidth: "100%", maxHeight: "100%", display: "block", margin: "0 auto" }} />
                    </div>
                </Fade>
            </Modal>

            <Grid container justifyContent="flex-end">
                <Grid item>
                    {!sigImgAddress && (submission.statusID === 2) &&
                        <div style={{ color: "red", lineHeight: "60px" }}>Du måste ha en signatur uppladdad för att godkänna mallen</div>
                    }
                </Grid>

                {!isLoading && <Grid item style={{ padding: "12px" }}>
                    {submission.statusID < 3 && <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "20px" }}
                        onClick={() => handleNext(1)}
                    >
                        {'Spara'}
                    </Button>}

                    {(submission.statusID === 1 && (documentTypeId === 18 || documentTypeId === 19 || documentTypeId === 7)) && <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNextWithPrompt(4)}
                        disabled={!allowProceed}
                    >
                        {'Spara och godkänn'}
                    </Button>}

                    {(submission.statusID === 1 && (documentTypeId !== 18 && documentTypeId !== 19 && documentTypeId !== 7)) && <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNextWithPrompt(2)}
                        disabled={!allowProceed}
                    >
                        {'Spara och skicka till ansvarig'}
                    </Button>}

                    {(submission.statusID === 2) && <> <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNextWithPrompt(3)}
                        disabled={!allowProceed || !sigImgAddress}
                    >
                        {'Godkänn'}
                    </Button> </>}

                    {submission.statusID === 3 && isArchive && < Button
                        variant="contained"
                        color="primary"
                        onClick={() => { window.location.href = '/#/app/anläggningar/anläggning?objektID=' + submission.objectID }}
                        disabled={!allowProceed}
                        className={classes.noPrint}
                    >
                        {'Tillbaka till anläggningen'}
                    </Button>}

                    {submission.statusID === 3 && !isArchive && <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { window.location.href = '/#/app/objekt/dokument?objektID=' + submission.objectID }}
                        disabled={!allowProceed}
                        className={classes.noPrint}
                    >
                        {'Tillbaka till dokumentlistan'}
                    </Button>}
                </Grid>}
            </Grid>
        </Grid>
    );
};

export default TemplateItem;